import React from 'react';

import Cover from './Cover';
import SectionProjectInfo from './SectionProjectInfo';
import SectionQuote from './SectionQuote';
import SectionColumns from './SectionColumns';
import SectionImage from './SectionImage';
import SectionChart from './SectionChart';
import SectionVideo from './SectionVideo';
import SectionCTA from './SectionCTA';

import { ReactComponent as Logo } from '../assets/projects/irp/logo.svg';
import { ReactComponent as Chapters } from '../assets/projects/irp/chapters.svg';
import { ReactComponent as ChaptersMobile } from '../assets/projects/irp/chapters-mobile.svg';

import video from '../assets/projects/irp/cover.mp4';
import poster from '../assets/projects/irp/cover.jpg';
import hyperIsland from '../assets/projects/irp/hyper-island.jpg';
import previewVideo from '../assets/projects/irp/preview.mp4';
import previewPoster from '../assets/projects/irp/preview.jpg';

const ProjectIRP = () => {
  return (
    <Cover
      id="divided-by-code"
      logo={<Logo />}
      tagline="My thesis about collaboration for creative technology"
      video={{ url: video, poster: poster.src }}
    >
      <SectionProjectInfo
        rows={[
          ['University', 'Hyper Island'],
          ['Year', '2017'],
          ['Technology', 'React, WebGL, ThreeJS, D3'],
          ['Role', 'Research, writing, development, design'],
        ]}
      />
      <SectionQuote quote="I’m trying to improve the collaboration between creatives and developers, to improve creative technology projects" />
      <SectionColumns
        left={{
          heading: 'Problem',
          content: [
            'Reflecting on my own history, the collaboration between creatives and developers has always been an uneasy one. Even though it can result in amazing outcomes, it often feels like an incredible struggle. It often feels easier and better manageable to just prevent those two disciplines from collaborating at all. Let creatives be creative, and let developers do their thing. Don’t play with fire.',
            'As you might have guessed, I myself am really interested in combing creativity with software & technology. I have a background in being creative, while also being able to let my ideas come to life by coding those myself. For me imagining an idea, and developing that idea myself are two sides of the same coin; “the act of making”. ',
          ],
        }}
        right={{
          content:
            'This all works really well when working alone of course since there is nobody there to hold you back (except yourself). Working on creative technology projects in university was like a dream came true. Soon after my bachelor, when working in the real world, it became clear to me that working as a cog in a bigger team isn’t easy. You’ll face a lot of complexities due to the collaboration with others. This means that synergy between creativity and development is often lost. You have to fit a label, you are a strategist, designer, developer or a project manager, you can’t be all right? This meant I became a developer. And all of a sudden the possibilities of me being creative felt lost behind a lot of different stakeholders and complexities.',
        }}
      />
      <SectionImage
        heading="Hyper Island"
        content="So I went back to university, to do my master at Hyper Island in Manchester. Hyper Island is not your usual kind of education. Originally started in Sweden, Hyper Island is known for it’s unorthodox, holistic and pragmatic style of education. To finish my Master I decided to aim my thesis subject at improving the collaboration with creatives and developers, which was the reason I joined Hyper Island in the first place. My thesis is of course heavily inspired by that ‘Hyper Island thinking’, and my learnings from it."
        image={hyperIsland}
        alt="Picture of my year at Hyper Island"
      />
      <SectionChart
        heading="Chapters"
        content="My research is following the following structure"
        chart={{ component: Chapters, width: 1149, height: 330 }}
        chartMobile={{
          component: ChaptersMobile,
          width: 333,
          height: 605,
          small: true,
        }}
      />
      <SectionVideo
        video={{
          src: previewVideo,
          poster: previewPoster.src,
          width: 1814,
          height: 1080,
        }}
      />
      <SectionQuote
        heading="Lesson learned"
        quote="Collaboration is playful, we shouldn’t be dogmatic but pragmatic in discovering the possibilities and limitations of our shared knowledge and skills"
      />
      <SectionCTA
        heading="Read"
        cta={{
          label: 'Visit thesis website',
          href: 'http://thesis.dpwoert.com',
        }}
      />
    </Cover>
  );
};

export default ProjectIRP;
