import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';

import SPACING from '../styles/spacing';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

import featureCustomControls from '../assets/projects/magic-circle/features/custom-controls.png';
import featurePresets from '../assets/projects/magic-circle/features/presets.png';
import featureScreenshots from '../assets/projects/magic-circle/features/screenshots.png';
import featureRecordings from '../assets/projects/magic-circle/features/recordings.png';
import featureTimeline from '../assets/projects/magic-circle/features/timeline.png';
import featurePerformance from '../assets/projects/magic-circle/features/performance.png';
import featureCustomPlugins from '../assets/projects/magic-circle/features/custom-plugins.png';
import featureSmallClient from '../assets/projects/magic-circle/features/small-clients.png';
import featureDeploy from '../assets/projects/magic-circle/features/deploy.png';

const Heading = styled.h2`
  ${TYPO.h3}
  ${Grid.columns(1, 12)}
  text-align: center;
  margin-top: ${SPACING(16)}px;
  margin-bottom: ${SPACING(9)}px;
`;

const Feature = styled.div`
  ${Grid.columns(3, 8)}
  display: flex;
  align-items: center;
  margin-bottom: ${SPACING(12)}px;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  ${BREAKPOINTS.max.small`
  ${Grid.columns(1, 12)}
  flex-direction: column;

    &:nth-child(odd) {
    flex-direction: column;
  }
  `}
`;

const Content = styled.div`
  ${TYPO.paragraph}
  text-align: left;
  margin-right: ${SPACING(9.5)}px;
  flex: 1;

  ${Feature}:nth-child(odd) & {
    text-align: right;
    margin-right: 0;
    margin-left: ${SPACING(9.5)}px;
  }

  ${BREAKPOINTS.max.small`
    text-align: center;
     margin-right: 0;

       ${Feature}:nth-child(odd) & {
        text-align: center;
        margin-left: 0;
       }
  `}
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${SPACING(7.5)}px;

  ${BREAKPOINTS.max.small`
       margin-top: ${SPACING(4)}px;
    `}
`;

const ImageInner = styled.div`
  position: relative;
  width: 100%;
`;

const FeatureHeading = styled.div`
  ${TYPO.h4}
  margin-bottom: ${SPACING(1)}px;
`;

const SectionMagicCircleFeatures = () => {
  return (
    <Grid.Container>
      <Heading>The features I needed</Heading>

      <Feature>
        <Content>
          <FeatureHeading>Control my variables</FeatureHeading>
          Enables me to play around with variables. All controls are
          configurable and adaptable to play nicely with most data sources. It
          comes with a good set of versatile controls for numbers, text,
          booleans, functions and colours.
        </Content>
        <ImageContainer>
          <ImageInner>
            <Image
              alt="controls emoji"
              src={featureCustomControls}
              layout="responsive"
            />
          </ImageInner>
        </ImageContainer>
      </Feature>

      <Feature>
        <Content>
          <FeatureHeading>Completely reproducable presets</FeatureHeading>
          Nothing worse then losing old variants of a project since you no
          longer know how to get it back in the same way. Magic circle enables
          me to create the exact same scene by saving the values of your
          variables, seeding value and your git state. I can save a preset and
          always go back in time and exactly recreate it.
        </Content>
        <ImageContainer>
          <ImageInner>
            <Image src={featurePresets} alt="box emoji" layout="responsive" />
          </ImageInner>
        </ImageContainer>
      </Feature>

      <Feature>
        <Content>
          <FeatureHeading>Screenshots</FeatureHeading>
          Take screenshots easily and in high-quality. The current state of the
          variables is saved with a screenshot as a preset. This means I can
          recreate that screenshot again. Especially since the current git state
          is also being stored, I can go back in time to re-create old presets.
        </Content>
        <ImageContainer>
          <ImageInner>
            <Image
              src={featureScreenshots}
              alt="camera emoji"
              layout="responsive"
            />
          </ImageInner>
        </ImageContainer>
      </Feature>

      <Feature>
        <Content>
          <FeatureHeading>Recordings</FeatureHeading>
          Render my content into a screen recording by exporting it frame by
          frame without lag. Enabling you to export videos in high quality
          without loss of quality or jankiness like for example a manual screen
          recording would.
        </Content>
        <ImageContainer>
          <ImageInner>
            <Image
              src={featureRecordings}
              alt="video camera emoji"
              layout="responsive"
            />
          </ImageInner>
        </ImageContainer>
      </Feature>

      <Feature>
        <Content>
          <FeatureHeading>Timeline</FeatureHeading>
          Create a timeline with keyframed values based on my variables. Use it
          to experiment with values or create production-ready animations.
        </Content>
        <ImageContainer>
          <ImageInner>
            <Image
              src={featureTimeline}
              alt="clapper board emoji"
              layout="responsive"
            />
          </ImageInner>
        </ImageContainer>
      </Feature>

      <Feature>
        <Content>
          <FeatureHeading>Performance measurements</FeatureHeading>
          Measures and displays performance metrics like Frames Per Second and
          memory usage so I always know if my projects run smoothly.
        </Content>
        <ImageContainer>
          <ImageInner>
            <Image
              src={featurePerformance}
              alt="chart emoji"
              layout="responsive"
            />
          </ImageInner>
        </ImageContainer>
      </Feature>

      <Feature>
        <Content>
          <FeatureHeading>Small client</FeatureHeading>
          The UI embeds my projects into a frame. This means the client code
          (the code you add to your project) is very very small (~5kb) since all
          the heavy lifting is done inside the UI which isn{`'`}t part of my
          project files. You don{`'`}t have to fear that Magic Circle will get
          bundled into your project. If you load your project outside of Magic
          Circle, it will just work as-asual.
        </Content>
        <ImageContainer>
          <ImageInner>
            <Image
              src={featureSmallClient}
              alt="chicklet emoji"
              layout="responsive"
            />
          </ImageInner>
        </ImageContainer>
      </Feature>

      <Feature>
        <Content>
          <FeatureHeading>Custom plugins</FeatureHeading>
          Since all projects are unique, some projects need custom plugins that
          might not exists yet. I can make bespoke ones if needed.
        </Content>
        <ImageContainer>
          <ImageInner>
            <Image
              src={featureCustomPlugins}
              alt="worktools emoji"
              layout="responsive"
            />
          </ImageInner>
        </ImageContainer>
      </Feature>

      <Feature>
        <Content>
          <FeatureHeading>Deploy</FeatureHeading>I can build and deploy this
          setup, so I can share it with others in my teams or the wider world.
        </Content>
        <ImageContainer>
          <ImageInner>
            <Image src={featureDeploy} alt="rocket emoji" layout="responsive" />
          </ImageInner>
        </ImageContainer>
      </Feature>
    </Grid.Container>
  );
};

export default SectionMagicCircleFeatures;
