import React, { useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';

import Button, { ButtonVariant } from './Button';
import TextAnimationBlur from './TextAnimationBlur';
import TextAnimationTransform from './TextAnimationTransform';

import COLORS from '../styles/colors';
import TYPO, { rem } from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

import me from '../assets/me.png';
import hyperIsland from '../assets/logos/black/hyper-island.png';
import cmd from '../assets/logos/black/cmd.png';
import dpdk from '../assets/logos/black/dpdk.png';
import omm from '../assets/logos/black/omm.jpeg';
import { ReactComponent as Dpwoert } from '../assets/logos/black/dpwoert.svg';
import { ReactComponent as Cleverfranke } from '../assets/logos/black/cleverfranke.svg';
import { ReactComponent as SignalNoise } from '../assets/logos/black/signal-noise.svg';
import { ReactComponent as Neverbland } from '../assets/logos/black/neverbland.svg';
import { ReactComponent as Flow } from '../assets/logos/black/flow.svg';

const IntroContainer = styled(Grid.Container)`
  margin-top: ${SPACING(16)}px;
`;

const IntroContent = styled(Grid.Cell)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${TYPO.paragraph}
  color: ${COLORS.dark.css};

  h2 {
    ${TYPO.h3}
    color: ${COLORS.black.css};
    margin-bottom: 32px;
  }

  p {
    margin-bottom: ${SPACING(2)}px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${BREAKPOINTS.max.small`
          margin-top: ${SPACING(4)}px;
      `}
`;

const ColumnsContainer = styled(Grid.Container)`
  margin-top: ${SPACING(10)}px;
`;

const ColumnsInside = styled(Grid.Cell)`
  display: flex;
  gap: ${SPACING(6)}px;

  ${BREAKPOINTS.max.small`
          flex-direction: column;
      `}
`;

const Column = styled.div`
  flex: 1;
  color: ${COLORS.dark.css};
  ${TYPO.paragraphSmall}

  h3 {
    ${TYPO.h4}
    color: ${COLORS.black.css};
    margin-bottom: ${SPACING(1.5)}px;
  }

  p {
    margin: 0;
    padding: 0;

    ${BREAKPOINTS.min.small`
        font-size: ${rem(14)};
    `}
  }

  ul {
    margin-top: ${SPACING(3)}px;
  }

  li {
    margin-bottom: ${SPACING(1.5)}px;
    ${TYPO.small}
    color: ${COLORS.dim.css};
  }
`;

const ExperienceContainer = styled(Grid.Container)`
  margin-top: ${SPACING(12)}px;
`;

const ExperienceHeading = styled(Grid.Cell)`
  display: flex;
  align-items: center;
  margin-bottom: ${SPACING(5)}px;
  gap: ${SPACING(2)}px;

  h3 {
    ${TYPO.h4}
    color: ${COLORS.black.css};
  }
`;

type ExperienceColumnProps = {
  hide?: boolean;
};

const ExperienceColumn = styled(Grid.Cell)<ExperienceColumnProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.hide ? '380px' : 'auto')};
  overflow: hidden;
`;

type ExperienceTransitionAreaProps = {
  show: boolean;
};

const ExperienceTransitionArea = styled.div<ExperienceTransitionAreaProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.2s ease;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? 'all' : 'none')};
`;

const ExperienceTransition = styled.div`
  height: 152px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;

const ShowMore = styled.div`
  background: ${COLORS.white.css};
  padding-left: 120px;
`;

const ExperienceCell = styled.div`
  display: flex;
  margin-bottom: ${SPACING(3)}px;

  ${BREAKPOINTS.max.small`
    flex-direction: column;
    gap: 12px;
    margin-bottom: ${SPACING(5)}px;
  `}
`;

type ExperienceLogoProps = {
  customWidth?: number;
};

const ExperienceLogo = styled.div<ExperienceLogoProps>`
  width: ${(props) => props.customWidth || 105}px;
  margin-right: ${(props) => 105 - (props.customWidth || 105)}px;
`;

const ExperienceData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 64px;

  div {
    margin-botton: 4px;
  }

  div:nth-child(3n + 1) {
    ${TYPO.h5}
    color: ${COLORS.black.css};
  }
  div:nth-child(3n + 2) {
    ${TYPO.regular2}
    color: ${COLORS.dark.css};
  }
  div:nth-child(3n + 3) {
    ${TYPO.small}
    color: ${COLORS.dim.css};
  }

  div:nth-child(3n + 4) {
    margin-top: ${SPACING(1)}px;
  }

  ${BREAKPOINTS.max.small`
      margin-left: 0;
  `}
`;

const AwardContainer = styled(Grid.Container)`
  margin-top: ${SPACING(12)}px;
`;

const AwardHeading = styled(Grid.Cell)`
  margin-bottom: ${SPACING(1.5)}px;

  h3 {
    ${TYPO.h4}
    color: ${COLORS.black.css};
  }
`;

const AwardInner = styled(Grid.Cell)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING(1.5)}px;

  li {
    ${TYPO.writingPost}
    color: ${COLORS.dark.css};
  }

  span {
    ${TYPO.small}
    color: ${COLORS.dim.css};
    margin-left: ${SPACING(0.5)}px;
  }

  ${BREAKPOINTS.max.small`
          margin-top: ${SPACING(4)}px;
      `}
`;

const About = () => {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <IntroContainer id="about">
        <Grid.Cell
          start={2}
          columns={3}
          responsive={{ small: { start: 2, columns: 10 } }}
        >
          <Image layout="responsive" src={me} alt="A picture of myself" />
        </Grid.Cell>
        <IntroContent
          start={6}
          columns={6}
          responsive={{ small: { start: 2, columns: 10 } }}
        >
          <h2>
            <TextAnimationTransform>Hello I am Davey,</TextAnimationTransform>
          </h2>
          <p>
            I am a creative individual who enjoys leveraging technology to
            explore new possibilities. Whether you consider me a designer with a
            love for engineering or an engineer with a keen eye for design, one
            thing is certain—I thrive as a maker and problem solver. Nothing
            excites me more than working on complex digital projects and using
            my skills to find creative and well-suited solutions. Seeing
            projects come to life is an amazing feeling. When done well a
            project feels like magic, very simple and intuitive on the surface
            but fascinatingly complex below.
          </p>
          <p>
            I am originally from The Netherlands but now London based. I have
            over 10 years of industry experience in both digital & design
            agencies and early-stage startups. I love to work with a diverse set
            of people, clients and companies. I like every project to be a new
            adventure, a great opportunity to explore different perspective and
            to push myself in improving in my craft.
          </p>
        </IntroContent>
      </IntroContainer>
      <ColumnsContainer>
        <ColumnsInside start={2} columns={10}>
          <Column>
            <h3>
              <TextAnimationBlur>Design</TextAnimationBlur>
            </h3>
            <p>
              I have a varied background in design. With my skill set, working
              on data visualisation and generative art has been a natural match
              at the start of my career. Over time, I have proactively broadened
              my expertise to include product design, digital design, and
              campaign based media projects.
            </p>
            <ul>
              <li>Ideation</li>
              <li>Design thinking</li>
              <li>Data visualisation</li>
              <li>Generative {'&'} procedural design</li>
              <li>UI / UX design</li>
              <li>Product thinking</li>
              <li>Rapid prototyping</li>
              <li>Figma, Sketch and Adobe CS</li>
            </ul>
          </Column>
          <Column>
            <h3>
              <TextAnimationBlur delay={0.2}>Technology</TextAnimationBlur>
            </h3>
            <p>
              I love bringing projects to live with the use of technlogy. I have
              worked a lot on both 2D and 3D projects, mainly aimed for the web
              but also as physical instalations. I can make pixel perfect user
              interfaces for products, fun 3D experiences in WebGL and very
              complex visualisations powered by data.
            </p>
            <ul>
              <li>Javascript / Typescript</li>
              <li>ReactJS {'&'} Next</li>
              <li>(S)CSS {'&'} HTML</li>
              <li>WebGL {'&'} GLSL</li>
              <li>ThreeJS</li>
              <li>WebAR</li>
              <li>D3</li>
              <li>NodeJS ecosystem</li>
              <li>Electron</li>
              <li>CI / CD</li>
              <li>AWS {'&'} Azure</li>
              <li>Data analysis</li>
              <li>Geospatial data</li>
            </ul>
          </Column>
          <Column>
            <h3>
              <TextAnimationBlur delay={0.4}>Collaboration</TextAnimationBlur>
            </h3>
            <p>
              No project is ever really done on one{`'`}s own. Collaboration is
              always key to success. It{`'`}s therefore important to push
              yourself, and the team you are working with in creating a good
              work environment and team culture where everyone can thrive.
            </p>
            <ul>
              <li>(Self) leadership</li>
              <li>Team culture</li>
              <li>Process setup {'&'} management</li>
              <li>Workshop facilitation</li>
              <li>Early stage start-ups</li>
              <li>Agile methodologies</li>
              <li>Open Source development</li>
            </ul>
          </Column>
        </ColumnsInside>
      </ColumnsContainer>
      <ExperienceContainer>
        <ExperienceHeading start={2} columns={10}>
          <h3>
            <TextAnimationBlur>Experiences</TextAnimationBlur>
          </h3>
          <Button
            link={{ href: 'https://dpwoert.com/cv.pdf', target: '_blank' }}
            variant={ButtonVariant.SMALL}
          >
            Download CV
          </Button>
        </ExperienceHeading>
        <ExperienceColumn
          start={2}
          columns={5}
          responsive={{ small: { start: 2, columns: 10 } }}
        >
          <ExperienceCell>
            <ExperienceLogo>
              <Image
                layout="responsive"
                src={hyperIsland}
                alt="Hyper Island logo"
              />
            </ExperienceLogo>
            <ExperienceData>
              <div>MA Digital media management</div>
              <div>Manchester (UK), 2016</div>
              <div>Hyper Island</div>
            </ExperienceData>
          </ExperienceCell>
          <ExperienceCell>
            <ExperienceLogo>
              <Image
                layout="responsive"
                src={cmd}
                alt="Communication and Multimedia design logo"
              />
            </ExperienceLogo>
            <ExperienceData>
              <div>BSc Communication {'&'} Multimedia Design</div>
              <div>Den Bosch (NL), 2010 - 2014</div>
              <div>Avans University of Applied Science</div>
            </ExperienceData>
          </ExperienceCell>
        </ExperienceColumn>
        <ExperienceColumn
          columns={5}
          hide={!expand}
          responsive={{ small: { start: 2, columns: 10 } }}
        >
          <ExperienceCell>
            <ExperienceLogo customWidth={55}>
              <Image layout="responsive" src={omm} alt="OMM logo" />
            </ExperienceLogo>
            <ExperienceData>
              <div>Tech Lead</div>
              <div>London (UK), 2022 - current</div>
              <div>Leading the dev team on creative technology projects</div>
            </ExperienceData>
          </ExperienceCell>

          <ExperienceCell>
            <ExperienceLogo>
              <Flow width={78} height={30} />
            </ExperienceLogo>
            <ExperienceData>
              <div>Lead front-end developer</div>
              <div>London (UK), 2020-2022</div>
              <div>Responsible for design and the front-end team</div>
            </ExperienceData>
          </ExperienceCell>

          <ExperienceCell>
            <ExperienceLogo>
              <Neverbland width={105} height={30} />
            </ExperienceLogo>
            <ExperienceData>
              <div>Senior developer</div>
              <div>London (UK), 2018-2020</div>
              <div>Creative technology, product development, prototyping</div>
            </ExperienceData>
          </ExperienceCell>

          <ExperienceCell>
            <ExperienceLogo>
              <SignalNoise width={105} height={30} />
            </ExperienceLogo>
            <ExperienceData>
              <div>Developer</div>
              <div>London (UK), 2016-2018</div>
              <div>Creative technology, 3D data visualisation, development</div>
            </ExperienceData>
          </ExperienceCell>

          <ExperienceCell>
            <ExperienceLogo>
              <Cleverfranke width={105} height={30} />
            </ExperienceLogo>
            <ExperienceData>
              <div>Developer</div>
              <div>Utrecht (NL), 2014-2015</div>
              <div>
                Creative development, front-end development and visualising data
              </div>

              <div>Graduation internship</div>
              <div>Utrecht (NL), 2014</div>
              <div>Researching the best way to visualise survey data</div>
            </ExperienceData>
          </ExperienceCell>

          <ExperienceCell>
            <ExperienceLogo>
              <Dpwoert width={105} height={30} />
            </ExperienceLogo>
            <ExperienceData>
              <div>Freelance</div>
              <div>Eindhoven/London, 2014 - 2016</div>
              <div>Creative technology, development, design, concepting</div>
            </ExperienceData>
          </ExperienceCell>

          <ExperienceCell>
            <ExperienceLogo customWidth={55}>
              <Image layout="responsive" src={dpdk} alt="DPDK logo" />
            </ExperienceLogo>
            <ExperienceData>
              <div>Internship</div>
              <div>Rotterdam (NL), 2012 - 2013</div>
              <div>Front-end development</div>
            </ExperienceData>
          </ExperienceCell>
          <ExperienceTransitionArea show={!expand}>
            <ExperienceTransition />
            <ShowMore>
              <Button
                variant={ButtonVariant.DIM}
                onClick={() => setExpand(true)}
              >
                Show more
              </Button>
            </ShowMore>
          </ExperienceTransitionArea>
        </ExperienceColumn>
      </ExperienceContainer>
      <AwardContainer>
        <AwardHeading
          start={2}
          columns={5}
          responsive={{ small: { start: 2, columns: 10 } }}
        >
          <h3>
            <TextAnimationBlur>Recognition</TextAnimationBlur>
          </h3>
        </AwardHeading>
        <AwardInner
          columns={4}
          responsive={{ small: { start: 2, columns: 10 } }}
        >
          <li>European Design Award</li>
          <li>
            Webby <span>Honarable mention</span>
          </li>
          <li>
            Red Dot <span>Best of the best</span>
          </li>
          <li>
            Innovation by Design Awards <span>Finalist</span>
          </li>
          <li>
            Spin Awards <span>Finalist</span>
          </li>
          <li>FWA</li>
          <li>Awwwards</li>
          <li>CSS Awards</li>
        </AwardInner>
      </AwardContainer>
    </>
  );
};

export default About;
