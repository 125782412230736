import React from 'react';

import Cover from './Cover';
import SectionProjectInfo from './SectionProjectInfo';
import SectionQuote from './SectionQuote';
import SectionColumns from './SectionColumns';
import SectionImageSticky from './SectionImageSticky';
import SectionChart from './SectionChart';
import SectionVideo from './SectionVideo';

import { ReactComponent as Logo } from '../assets/projects/3d-cities/logo.svg';
import { ReactComponent as DataAnalysis } from '../assets/projects/3d-cities/data-analysis.svg';
import { ReactComponent as DataAnalysisMobile } from '../assets/projects/3d-cities/data-analysis-mobile.svg';

import video from '../assets/projects/3d-cities/cover.mp4';
import poster from '../assets/projects/3d-cities/cover.jpg';
import previewVideo from '../assets/projects/3d-cities/showreel.mp4';
import previewPoster from '../assets/projects/3d-cities/showreel.jpg';
import printing1 from '../assets/projects/3d-cities/printing-1.jpg';
import printing2 from '../assets/projects/3d-cities/printing-2.jpg';

const Project3dCities = () => {
  return (
    <Cover
      id="3d-cities"
      logo={<Logo />}
      tagline="Making cities come to life with data"
      video={{ url: video, poster: poster.src }}
    >
      <SectionProjectInfo
        rows={[
          ['Agency', 'Self initiated, Signal Noise, CLEVER°FRANKE'],
          ['Year', '2014-2018'],
          ['Technology', 'QGis, WebGL, ThreeJS, D3'],
          ['Role', 'Data analysis, development, design'],
        ]}
      />
      <SectionQuote quote="Exploring more emotional and intuitive forms of storytelling with data by making cities and maps come alive in 3d." />
      <SectionColumns
        left={{
          heading: 'Problem',
          content:
            'Visualising geospatial data is often done through map overlays on products like Google Maps or Mapbox. Although being a good tool to transfer numerical insights to users, it sometimes lacks storytelling depth when working with bigger data sets. For those data sets, you want to tell a story your users can relate to emotionally and intuitively. Feel the numbers, not read them.',
        }}
        right={{
          heading: 'Solution',
          content:
            'Experimenting with 3d maps originally started during my bachelor. During the last semester, our briefing was to visualise local pollution data for the local newspaper. To do so I decided to experiment with making 3d maps based on open data. I first wanted to recreate the skyline of the city. To do so I merged height (lidar) data with cadastral data, with this it is possible to create 3d objects by extruding the floorplan of buildings. By using Open Street Maps I’ve managed to add other map vector layers too, like streets, parks and waterways. This eventually resulted in a project I called CityIO, and served as a base for all other 3d city projects I’ve made after.',
        }}
      />
      <SectionChart
        heading="Data analysis"
        content="These visualisations are created using the following processes to convert data in 3d graphics"
        chart={{ component: DataAnalysis, width: 1222, height: 288 }}
        chartMobile={{ component: DataAnalysisMobile, width: 712, height: 451 }}
      />
      <SectionVideo
        video={{
          src: previewVideo,
          poster: previewPoster.src,
          width: 1920,
          height: 720,
        }}
      />
      <SectionImageSticky
        images={[printing1, printing2]}
        heading="3D printing"
        alt=""
        content="As a spinoff of these online WebGL visualisations, I’ve created 3d prints using the same techniques. By exporting these WebGL models from the browser, they could be printed in a 3d printer after some slight retouching in a 3d program. Since printing a whole city would be too costly, the end form is a 3d printed terrain visualising the average building age of the Dutch city Den Bosch. The higher the terrain, the newer that part of the city is. The red part is the old city centre, and the middle line is 1945 since major urban growth started after the second world war."
      />
      <SectionQuote
        heading="Lesson learned"
        quote="Data visualisation is more than visualising numbers. Make people feel the numbers by telling a good story they can intuitively and emotionally read."
      />
    </Cover>
  );
};

export default Project3dCities;
