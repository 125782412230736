import React from 'react';
import styled from 'styled-components';

import SPACING from '../styles/spacing';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

import { ReactComponent as ReactLogo } from '../assets/projects/magic-circle/icons/react.svg';
import { ReactComponent as ThreeLogo } from '../assets/projects/magic-circle/icons/three.svg';
import { ReactComponent as PixiLogo } from '../assets/projects/magic-circle/icons/pixi.svg';
import { ReactComponent as P5Logo } from '../assets/projects/magic-circle/icons/p5.svg';
import { ReactComponent as ReglLogo } from '../assets/projects/magic-circle/icons/regl.svg';

const Inner = styled(Grid.Cell)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${SPACING(12)}px;
`;

const Heading = styled.h2`
  ${TYPO.h3}
  text-align: center;
`;

const Logos = styled.div`
  display: flex;
  gap: ${SPACING(2)}px;
  margin-top: ${SPACING(7)}px;

  ${BREAKPOINTS.max.small`
    flex-wrap: wrap;
    justify-content: center;
  `}
`;

const Logo = styled.div`
  ${TYPO.small}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 90px;

  ${BREAKPOINTS.max.small`
    margin-top: ${SPACING(2)}px;
  `}
`;

const SectionMagicCircleFrameworks = () => {
  return (
    <Grid.Container>
      <Inner
        start={3}
        columns={8}
        responsive={{
          small: {
            start: 1,
            columns: 12,
          },
        }}
      >
        <Heading>Works with anything</Heading>
        <Logos>
          <Logo>
            <ThreeLogo />
            THREE.js
          </Logo>
          <Logo>
            <P5Logo />
            p5.js
          </Logo>
          <Logo>
            <ReglLogo />
            Regl
          </Logo>
          <Logo>
            <ReactLogo />
            React
          </Logo>
          <Logo>
            <PixiLogo />
            Pixi.js
          </Logo>
        </Logos>
      </Inner>
    </Grid.Container>
  );
};

export default SectionMagicCircleFrameworks;
