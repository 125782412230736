import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import useInview from '../hooks/useInview';

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

type FallbackProps = {
  image: string;
  position?: 'center' | 'top' | 'bottom';
};

const Fallback = styled.div<FallbackProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  background: url(${(props) => props.image});
  background-size: cover;
  background-position: ${(props) => props.position || 'center'} center;
`;

type PlayerProps = {
  fit: 'cover' | 'contain';
  position?: 'center' | 'top' | 'bottom';
};

const Player = styled.video<PlayerProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: ${(props) => props.fit || 'cover'};
  object-position: ${(props) => props.position || 'center'} center;
`;

type VideoPlayerProps = {
  src: string;
  poster: string;
  fallbackOnMobile?: boolean;
  fit?: 'cover' | 'contain';
  position?: 'center' | 'top' | 'bottom';
  update?: (evt: any) => void;
};

const VideoPlayer = ({
  src,
  poster,
  fallbackOnMobile,
  update,
  fit,
  position,
  ...props
}: VideoPlayerProps) => {
  const [mobile, setMobile] = useState(false);
  const isPlaying = useRef(false);

  // Only play when inview
  const [ref, inView] = useInview({ once: false });

  // Play/pause when needed
  useEffect(() => {
    if (ref && ref.current) {
      if (inView) {
        try {
          const promise = ref.current.play();

          // Handle play state
          if (promise) {
            promise.then(() => {
              isPlaying.current = true;
            });
          } else {
            isPlaying.current = true;
          }
        } catch (e) {
          console.error(e);
        }
      } else if (isPlaying.current) {
        ref.current.pause();
        isPlaying.current = false;
      }
    }
  }, [ref, inView]);

  // Keep track of status if needed
  useEffect(() => {
    const element = ref.current;

    const playEvent = () => {
      isPlaying.current = true;
    };

    if (update && element) {
      element.addEventListener('play', playEvent);
      element.addEventListener('timeupdate', update);
    }

    setMobile(window.innerWidth < 640);

    return () => {
      if (update && element) {
        element.removeEventListener('play', playEvent);
        element.removeEventListener('timeupdate', update);
      }
    };
  }, [ref, update]);

  return (
    <Container {...props}>
      {mobile && fallbackOnMobile ? (
        <Fallback image={poster} position={position} />
      ) : (
        <Player
          fit={fit}
          position={position}
          ref={ref}
          poster={poster}
          playsInline
          loop
          muted
          controls={false}
        >
          <source src={src} type="video/mp4" />
        </Player>
      )}
    </Container>
  );
};

export default VideoPlayer;
