import React from 'react';
import styled from 'styled-components';

import TextAnimationBlur from './TextAnimationBlur';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

const Container = styled(Grid.Container)`
  margin-top: ${SPACING(28)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};

  ${BREAKPOINTS.max.small`
        margin-top: ${SPACING(12)}px;

      `}
`;

const Heading = styled(Grid.Cell)`
  margin-bottom: ${SPACING(12)}px;
  ${TYPO.regular}
  color: ${COLORS.dim.css};

  ${BREAKPOINTS.max.small`
        margin-top: ${SPACING(6)}px;

      `}

  h3 {
    ${TYPO.h3}
    color: ${COLORS.white.css};
    margin-bottom: ${SPACING(1)}px;

    ${BREAKPOINTS.max.small`
    ${TYPO.h3}
  `}
  }
`;

const DesktopCell = styled(Grid.Cell)`
  ${BREAKPOINTS.max.small`
        display: none;
      `}
`;

const MobileCell = styled(Grid.Cell)`
  display: none;

  ${BREAKPOINTS.max.small`
        display: block;
      `}
`;

type ChartContainerProps = {
  width: number;
  height: number;
};

const ChartContainer = styled.div<ChartContainerProps>`
  position: relative;
  width: 100%;
  padding-top: ${(props) => (props.height / props.width) * 100}%;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

type SectionChartProps = {
  heading: string;
  content: string;
  chart: {
    component: any;
    width: number;
    height: number;
  };
  chartMobile?: {
    component: any;
    width: number;
    height: number;
    small?: boolean;
  };
};

const SectionChart = ({
  heading,
  content,
  chart,
  chartMobile,
}: SectionChartProps) => {
  return (
    <Container>
      <Heading
        start={2}
        columns={10}
        responsive={{ small: { start: 0, columns: 12 } }}
      >
        <h3>
          <TextAnimationBlur>{heading}</TextAnimationBlur>
        </h3>
        {content}
      </Heading>
      <DesktopCell
        start={2}
        columns={10}
        responsive={{ small: { start: 0, columns: 12, hide: !!chartMobile } }}
      >
        <ChartContainer width={chart.width} height={chart.height}>
          <chart.component width="100%" height="100%;" />
        </ChartContainer>
      </DesktopCell>
      {chartMobile && (
        <MobileCell
          start={2}
          columns={10}
          responsive={{
            small: chartMobile.small
              ? { start: 3, columns: 8 }
              : { start: 1, columns: 12 },
          }}
        >
          <ChartContainer width={chartMobile.width} height={chartMobile.height}>
            <chartMobile.component width="100%" height="100%;" />
          </ChartContainer>
        </MobileCell>
      )}
    </Container>
  );
};

export default SectionChart;
