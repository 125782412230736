import React from 'react';

import Cover from './Cover';
import SectionProjectInfo from './SectionProjectInfo';
import SectionQuote from './SectionQuote';
import SectionColumns from './SectionColumns';
import SectionImageFullscreen from './SectionImageFullscreen';
import SectionCTA from './SectionCTA';

import { ReactComponent as Logo } from '../assets/projects/smart-telemetry/logo.svg';

import video from '../assets/projects/smart-telemetry/cover.mp4';
import poster from '../assets/projects/smart-telemetry/cover.jpg';
import preview from '../assets/projects/echo-system/slide-1.png';

const ProjectSmartTelemetry = () => {
  return (
    <Cover
      id="smart-telemetry"
      logo={<Logo />}
      tagline="Visualsing driving behaviour in the UK"
      video={{ url: video, poster: poster.src }}
      client="signal-noise"
    >
      <SectionProjectInfo
        rows={[
          ['Agency', 'Signal Noise'],
          ['Year', '2018'],
          ['Technology', 'Electron, NodeJS, WebGL, ThreeJS, D3'],
          ['Role', 'Design, development'],
        ]}
      />
      <SectionQuote quote="Making telemetry data come alive so the wealth of data becomes clear to stakeholders." />
      <SectionColumns
        left={{
          heading: 'Problem',
          content:
            'The client for this visualisation was looking for help in visualizing the large amount of telemetry data they possessed. This telemetry data is being collected by an app that tracks it users by GPS and phone sensors. Even though this team was busy collecting this large set of data, they were struggling to prove to their shareholders how much wealth is in this data set.',
        }}
        right={{
          heading: 'Solution',
          content:
            'Creating a tool that enables the user to create data stories with this telemetry data. There are multiple metrics available per ride in this data set like; speed, braking force, driver score car brands and user groups. To create a story a user can select a city, metric and setup the parameters for visualising and, if needed, apply filters to the data set. A user can then run the animation that runs through the whole day. Next to this the user can control the camera and clock themselves to see patterns at different times.',
        }}
      />
      <SectionImageFullscreen
        image={preview}
        alt="Preview of the smart telemetry visualisation"
      />
      <SectionQuote
        heading="Lesson learned"
        quote="By creating data stories viewers can explore the wealth of a data set in a more comprehensible way."
      />
      <SectionCTA
        heading="View"
        cta={{
          label: 'View full day render',
          href: 'https://vimeo.com/351925851',
        }}
      />
    </Cover>
  );
};

export default ProjectSmartTelemetry;
