import React from 'react';

import Menu from 'components/Menu';
import Splash from 'components/Splash';
import ProjectsHeading from 'components/ProjectsHeading';
import About from 'components/About';
import Writing from 'components/Writing';
import Contact from 'components/Contact';

import ProjectFlow from 'components/ProjectFlow';
import ProjectIrp from 'components/ProjectIrp';
import ProjectLick from 'components/ProjectLick';
import Project3dCities from 'components/Project3dCities';
import ProjectGentrificationForecast from 'components/ProjectGentrificationForecast';
import ProjectEchoSystem from 'components/ProjectEchoSystem';
import ProjectSmartTelemetry from 'components/ProjectSmartTelemetry';
import ProjectWebAR from 'components/ProjectWebAR';
import ProjectMagicCircle from 'components/ProjectMagicCircle';

export default function PageIndex() {
  return (
    <>
      <Menu />
      <Splash />
      <ProjectsHeading />
      <ProjectFlow />
      <ProjectIrp />
      <Project3dCities />
      <ProjectMagicCircle />
      <ProjectGentrificationForecast />
      <ProjectLick />
      <ProjectEchoSystem />
      <ProjectSmartTelemetry />
      <ProjectWebAR />
      <About />
      <Writing />
      <Contact />
    </>
  );
}
