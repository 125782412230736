/* eslint-disable consistent-return */
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components';
import { MagicCircle } from '@magic-circle/client';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

import FixedContainer from './FixedContainer';
import TextAnimationTransform from './TextAnimationTransform';

import useInview from '../hooks/useInview';

import createScene from '../3D/createScene';
import showWebGL from '../3D/detection';

import fallback from '../assets/fallback.png';

const Container = styled.div`
  position: relative;
  height: calc(100vh + 800px);
  min-height: 1200px;
  width: 100%;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 16, 35, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: ${COLORS.black.css};
`;

const Inner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const FirstScreen = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  color: ${COLORS.white.css};
  flex-direction: column;
`;

const Name = styled.div`
  ${TYPO.h3}
`;

const Tagline = styled.div`
  ${TYPO.regular}
`;

type TaglineBarProps = {
  position: 'left' | 'right';
};

const TaglineBar = styled.div<TaglineBarProps>`
  position: absolute;
  bottom: 0px;
  right: ${(props) => (props.position === 'left' ? 0 : 67)}px;
  height: 2px;
  width: ${(props) => (props.position === 'left' ? 48 : 148)}px;
  background: ${COLORS.accent.css};
  transition: width 0.3s ease, right 0.2s ease;
  pointer-events: none;
  border-radius: 3px;
`;

const Canvas = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: ew-resize;

  canvas {
    position: sticky;
    top: 0;
  }
`;

const Motivation = styled(Grid.Cell)`
  margin-top: ${SPACING(24)}px;
  color: ${COLORS.white.css};
  ${TYPO.quote}

  ${BREAKPOINTS.max.small`
    margin-top: 0;
  `}
`;

type FallbackProps = {
  show: boolean;
};

const Fallback = styled.div<FallbackProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url(${fallback.src});
  background-size: cover;
  background-position: center center;
`;

// const JobAd = styled.a`
//   position: absolute;
//   top: 0;
//   left: 50%;
//   transform: translateX(-50%);
//   background: ${COLORS.white.css};
//   color: ${COLORS.dark.css};
//   padding: ${SPACING(0.5)}px ${SPACING(1)}px;
//   border-radius: 0px 0px 5px 5px;
//   display: block;
//   ${TYPO.micro};

//   ${BREAKPOINTS.max.small`
//     left: 0;
//     width: 100%;
//     text-align: center;
//     transform: none;
//     border-radius: 0;
//   `}
// `;

const Transition = styled.div`
  position: absolute;
  width: 100%;
  height: 70px;
  left: 0px;
  bottom: 0px;
  display: none;

  background: linear-gradient(180deg, rgba(13, 13, 13, 0) 0%, #0d0d0d 100%);
`;

const Splash = () => {
  const [magic, setMagic] = useState<MagicCircle>(null);
  const [hasWebGL, setHasWebGL] = useState(false);
  const canvas = useRef(null);
  const [barPosition, setBarPosition] = useState<'left' | 'right'>('left');
  const [inViewRef, isInview] = useInview({});

  // create world when component is mounted
  useLayoutEffect(() => {
    if (showWebGL && canvas.current) {
      const element = canvas.current;
      const [scene, w] = createScene(element, setBarPosition);
      scene.start();
      setMagic(scene);
      setHasWebGL(true);

      // listen to resizes
      window.addEventListener('resize', () => () => w.resize());

      return () => {
        scene.stop();
        element.innerHTML = '';
      };
    }
  }, []);

  // only render when in view
  useEffect(() => {
    if (showWebGL && magic) {
      if (isInview) {
        magic.start();
      } else {
        // magic.stop();
      }
    }
  }, [isInview, magic]);

  return (
    <Container ref={inViewRef}>
      <Fallback show={!hasWebGL} />
      <Canvas ref={canvas} />
      <Inner>
        <FirstScreen>
          <Logo>
            <Name>Davey van der Woert</Name>
            <Tagline>Creative technology {'&'} design</Tagline>
            <TaglineBar position={barPosition} />
          </Logo>
        </FirstScreen>
        <Grid.Container>
          <Motivation start={3} columns={8}>
            <TextAnimationTransform>
              I like to believe I am making magic by working on the intersection
              between design and technology using common sense and good
              collaboration.
            </TextAnimationTransform>
          </Motivation>
        </Grid.Container>
      </Inner>
      <FixedContainer copy="Scroll down" />
      {/* <JobAd href="/#contact">
        I am currently looking for a contract or permanent job in London (UK) or
        remote
      </JobAd> */}
      <Transition />
    </Container>
  );
};

export default Splash;
