import React from 'react';
import styled from 'styled-components';

import useInview from '../hooks/useInview';

type ImageStyledProps = {
  inView: boolean;
  delay: number;
};

const Container = styled.div<ImageStyledProps>`
  transform: scale(${(props) => (props.inView ? 1 : 0.8)})
    translateY(${(props) => (props.inView ? 10 : 0)}px);
  filter: blur(${(props) => (props.inView ? 0 : 3)});
  tranform-origin: center center;
  transition: transform 0.2s ease-out 0s, blur 0.2s ease-in 0.15s;
`;

type AnimationZoomProps = {
  delay?: number;
  children: React.ReactNode;
};

const AnimationZoom = ({ children, delay = 0 }: AnimationZoomProps) => {
  const [ref, inView] = useInview({ once: true });
  return (
    <Container inView={inView} delay={delay} ref={ref}>
      {children}
    </Container>
  );
};

export default AnimationZoom;
