import React from 'react';
import styled from 'styled-components';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';

const Container = styled.div`
  position: fixed;
  bottom: ${SPACING(3)}px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${COLORS.dim.css};
  gap: ${SPACING(3)}px;
  z-index: 9;

  ${TYPO.small}
`;

const Item = styled.a`
  transition: color 0.2s ease;

  &:hover {
    color: ${COLORS.white.css};
  }
`;

const Menu = () => {
  return (
    <Container>
      <Item href="/#projects">Projects</Item>
      <Item href="/#about">About</Item>
      <Item href="/#writing">Writing</Item>
      <Item href="/#contact">Contact</Item>
    </Container>
  );
};

export default Menu;
