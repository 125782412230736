import React from 'react';
import styled, { keyframes } from 'styled-components';

import COLORS from '../styles/colors';
import TYPO, { rem } from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';

import { ReactComponent as ArrowDown } from '../assets/icons/arrow-down.svg';

const Container = styled.div`
  width: 100%;
  background: ${COLORS.black.css};
  color: ${COLORS.white.css};
`;

const Projects = styled.div`
  padding-top: ${SPACING(33)}px;
  padding-left: ${SPACING(2)}px;
  padding-bottom: ${SPACING(7)}px;
  background: ${COLORS.black.css};
  color: ${COLORS.white.css};

  ${BREAKPOINTS.max.small`
       padding-bottom: ${SPACING(6)}px;
       padding-bottom: ${SPACING(2)}px;

  `}
`;

const Heading = styled.h1`
  ${TYPO.h1};
`;

const Subtitle = styled.h1`
  ${TYPO.regular};
  color: ${COLORS.dim.css};
  margin-top: ${rem(-11)};
  margin-left: ${rem(195)};

  ${BREAKPOINTS.max.small`
     display: none;
  `}
`;

const HeadingRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

const pulse = keyframes`
  0% {  
    transform: translateY(-10px) scale(1); 
    opacity: 0.5;
  }
  20% {  
    transform: translateY(0px) scale(1); 
    opacity: 1;
  }
  100% {  
    transform: translateY(-10px) scale(1); 
    opacity: 0.5;
  }

`;

const Arrow = styled(ArrowDown)`
  margin-bottom: ${SPACING(1)}px;
  animation: ${pulse} 2.5s ease;
  animation-iteration-count: infinite;

  ${BREAKPOINTS.max.small`
     margin-bottom: 0;
  `}
`;

const ProjectsHeading = () => {
  return (
    <Container>
      <Projects id="projects">
        <HeadingRow>
          <Heading>Projects</Heading>
          <Arrow width="46" height="46" />
        </HeadingRow>
        <Subtitle>Click on a project to read more</Subtitle>
      </Projects>
    </Container>
  );
};

export default ProjectsHeading;
