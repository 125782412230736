import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useInview from 'hooks/useInview';

type ContainerProps = {
  show: boolean;
  delay: number;
  iosFix: boolean;
};

const Container = styled.span<ContainerProps>`
  filter: ${(props) => {
    if (props.iosFix) return 'none';
    return `blur(${props.show ? 0 : 5}px)`;
  }};
  transition: filter 0.3s ease;
  transition-delay: ${(props) => 0.3 + props.delay}s;
`;

type TextAnimationBlurProps = {
  children: string;
  delay?: number;
};

const TextAnimationBlur = ({ children, delay = 0 }: TextAnimationBlurProps) => {
  const [ref, inView] = useInview({ once: true });
  const [iosFix, setIosFix] = useState(false);

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setIosFix(true);
      }, delay * 100 + 300);
    }
  }, [inView, delay]);

  return (
    <Container ref={ref} show={inView} delay={delay} iosFix={iosFix}>
      {children}
    </Container>
  );
};

export default TextAnimationBlur;
