import React from 'react';

import Cover from './Cover';
import SectionProjectInfo from './SectionProjectInfo';
import SectionQuote from './SectionQuote';
import SectionColumns from './SectionColumns';
import SectionImage from './SectionImage';
import SectionChart from './SectionChart';
import SectionVideo from './SectionVideo';

import { ReactComponent as Logo } from '../assets/projects/echo-system/logo.svg';
import { ReactComponent as DataAnalysis } from '../assets/projects/echo-system/process.svg';
import { ReactComponent as DataAnalysisMobile } from '../assets/projects/echo-system/process-mobile.svg';

import video from '../assets/projects/echo-system/cover.mp4';
import poster from '../assets/projects/echo-system/cover.jpg';
import previewVideo from '../assets/projects/echo-system/preview.mp4';
import previewPoster from '../assets/projects/echo-system/preview.jpg';
import dataCuration from '../assets/projects/echo-system/data-curation.png';

const ProjectEchoSystem = () => {
  return (
    <Cover
      id="echo-system"
      logo={<Logo />}
      tagline="Analyising and visualising online tribal behaviour"
      video={{ url: video, poster: poster.src }}
      client="signal-noise"
    >
      <SectionProjectInfo
        rows={[
          ['Agency', 'Self initiated, Signal Noise, CLEVER°FRANKE'],
          ['Year', '2018'],
          [
            'Technology',
            'Electron, NodeJS, WebGL, ThreeJS, Elastic Search, Gephi',
          ],
          ['Role', 'Research, concepting, analysis, development'],
        ]}
      />
      <SectionQuote quote="Making sense of what is happing in our online behaviour by creating a visualisation that runs on live data" />
      <SectionColumns
        left={{
          heading: 'Problem',
          content: [
            'The internet got rid of all physical barriers involved in communicating with others. This led to the utopian view that the internet could lead us to a better understanding of each other due to having access to a much wider range of views. Lately, that utopian view has been shattered. Our media consumption is leading to more and more extreme and polarized views. Instead of enriching the debate with a plurality of views, it seems like the opposite has happened. Different groups of people no longer seem to share even basic foundational beliefs.',
            'We’ve all become part of this tribal ecosystem. We confine ourselves to the beliefs and ideas of our tribes. Even though access to opposing views has become easy and even unpreventable, we choose to ignore those views and opinions. The truth and our ability to trust experts has become inferior to our tribal identities. We’ve created our own echo chambers whereby we only listen to reverberations that reinforce our existing tribal beliefs.',
          ],
        }}
        right={{
          heading: 'Solution',
          content:
            'The Echosystem is a data visualisation that captures this tribal ecosystem by scraping and analyzing thousands of news and twitter messages. By categorizing the users sending these messages tribes can be formed. All this data drives a large simulation that can best be explained as a “digital fish tank”. The ecosystem will change over time depending on the news and online discussions, which can be observed by looking at this visualisation.',
        }}
      />
      <SectionImage
        heading="Data curation"
        content={
          'The data for this project is scraped from news outlet and twitter sources. These news outlets are seen as (more or less) neutral and a baseline for the underlying graph making the media landscape. Next, I’ve made a curated list containing 600 prominent twitter opinion-makers which have been categorised in tribes and subtribes.'
        }
        image={dataCuration}
        alt="An image showing all online groups indentified for this project"
      />
      <SectionChart
        heading="Data analysis"
        content="The following tech stack has been used to fetch, analyse and visualise the data"
        chart={{ component: DataAnalysis, width: 1314, height: 400 }}
        chartMobile={{
          component: DataAnalysisMobile,
          width: 534,
          height: 1181,
        }}
      />
      <SectionVideo
        video={{
          src: previewVideo,
          poster: previewPoster.src,
          width: 1280,
          height: 720,
        }}
      />
      <SectionQuote
        heading="Lesson learned"
        quote="It's possible to do unconvential storytelling by using algorithms to visualise patterns based upon live data"
      />
    </Cover>
  );
};

export default ProjectEchoSystem;
