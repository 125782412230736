import React from 'react';
import styled from 'styled-components';

import Button from './Button';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

const Container = styled(Grid.Container)`
  margin-top: ${SPACING(24)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
`;

const Heading = styled(Grid.Cell)`
  ${TYPO.h3};
`;

const Content = styled(Grid.Cell)`
  display: flex;
  align-items: center;

  ${BREAKPOINTS.max.small`
    margin-top: ${SPACING(2)}px;
  `}
`;

type SectionCTAProps = {
  heading: string;
  cta: {
    label: string;
    href: string;
  };
};

const SectionCTA = ({ heading, cta }: SectionCTAProps) => {
  return (
    <Container>
      <Heading
        start={2}
        columns={4}
        responsive={{ small: { start: 2, columns: 10 } }}
      >
        {heading}
      </Heading>
      <Content columns={6} responsive={{ small: { start: 2, columns: 10 } }}>
        <Button link={{ href: cta.href, target: '_blank' }}>{cta.label}</Button>
      </Content>
    </Container>
  );
};

export default SectionCTA;
