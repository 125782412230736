import React from 'react';
import styled from 'styled-components';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';

export enum ButtonVariant {
  DEFAULT,
  DIM,
  SMALL,
  MICRO,
}

type ContainerProps = {
  variant: ButtonVariant;
};

const Container = styled.button<ContainerProps>`
  color: ${(props) =>
    props.variant === ButtonVariant.DEFAULT ||
    props.variant === ButtonVariant.SMALL
      ? COLORS.accent.css
      : COLORS.dim.css};
  border-radius: 5px;
  border: 2px solid
    ${(props) =>
      props.variant === ButtonVariant.DEFAULT ||
      props.variant === ButtonVariant.SMALL
        ? COLORS.accent.css
        : COLORS.dim.opacity(0.2)};
  padding: ${(props) =>
    props.variant === ButtonVariant.SMALL || props.variant === ButtonVariant.DIM
      ? `${SPACING(0.5)}px ${SPACING(1)}px`
      : `${SPACING(1)}px ${SPACING(2)}px`};
  ${TYPO.small}
`;

const Micro = styled.button`
  color: COLORS.dark.css;
  border-radius: 5px;
  background: ${COLORS.white.css};
  padding ${SPACING(0.5)}px ${SPACING(1)}px;
  ${TYPO.micro}
`;

type ButtonProps = {
  variant?: ButtonVariant;
  onClick?: () => void;
  link?: {
    href: string;
    target?: string;
  };
  children: React.ReactNode;
};

const Button = ({
  children,
  variant = ButtonVariant.DEFAULT,
  link,
  ...props
}: ButtonProps) => {
  const render = () => {
    if (variant === ButtonVariant.MICRO) {
      return <Micro {...props}>{children}</Micro>;
    }

    return (
      <Container variant={variant} {...props}>
        {children}
      </Container>
    );
  };

  if (link) {
    return (
      <a href={link.href} target={link.target}>
        {render()}
      </a>
    );
  }

  return render();
};

export default Button;
