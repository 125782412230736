import { useState, useEffect, useRef } from 'react';

type useInviewProps = {
  once?: boolean;
  id?: string;
  threshold?: number;
  triggerOnNoSupport?: boolean;
};

const useInview = ({
  once = true,
  threshold = 0.5,
  triggerOnNoSupport = true,
}: useInviewProps): any => {
  const [inView, setInView] = useState(false);

  const ref = useRef();

  useEffect(() => {
    // no intersectionObserver so element will always be visible
    if ('IntersectionObserver' in window === false) {
      if (triggerOnNoSupport) {
        setInView(true);
      }
      // eslint-disable-next-line
      return () => {};
    }

    // Listen to viewport updates for element
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(
        (entry) => {
          const { isIntersecting } = entry;

          if (!isIntersecting && inView && !once) {
            setInView(false);
          }
          if (isIntersecting && !inView) {
            setInView(true);
          }
        },
        {
          threshold,
        }
      );
    });

    // start observing
    if (ref.current) {
      observer.observe(ref.current);
    }

    // Stop observing when object is destroyed
    return function unbind() {
      observer.disconnect();
    };
  }, [ref, inView, setInView, once, threshold, triggerOnNoSupport]);

  return [ref, inView];
};

export default useInview;
