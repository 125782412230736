import React from 'react';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as ArrowDown } from '../assets/icons/arrow-down.svg';

import COLORS from '../styles/colors';
import SPACING from '../styles/spacing';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip: rect(0, auto, auto, 0);
  pointer-events: none;

  ${BREAKPOINTS.max.small`
    display: none;
  `}
`;

const Side = styled.div`
  position: fixed;
  top: 50%;
  right: ${SPACING(1)}px;
  transform: translateY(-50%) rotate(-90deg);
  transform-origin: center center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${TYPO.small}
  color: ${COLORS.dim.css}
`;

const pulse = keyframes`
  0% {  
    transform: rotate(90deg) translateY(0px) scale(1); 
    opacity: 1;
  }
  20% {  
    transform: rotate(90deg) translateY(5px) scale(1); 
    opacity: 0.7;
  }
  100% {  
    transform: rotate(90deg) translateY(0px) scale(1); 
    opacity: 1;
  }

`;

const Arrow = styled(ArrowDown)`
  transform: rotate(90deg);
  margin-right: ${SPACING(0.5)}px;
  animation: ${pulse} 3s ease;
  animation-iteration-count: infinite;
  animation-delay: 2s;
`;

type FixedContainerProps = {
  copy: string;
};

const FixedContainer = ({ copy }: FixedContainerProps) => {
  return (
    <Container>
      <Side>
        <Arrow /> {copy}
      </Side>
    </Container>
  );
};

export default FixedContainer;
