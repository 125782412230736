import React from 'react';

import Cover from './Cover';
import SectionProjectInfo from './SectionProjectInfo';
import SectionQuote from './SectionQuote';
import SectionColumns from './SectionColumns';
import SectionImage from './SectionImage';
import SectionChart from './SectionChart';
import SectionVideo from './SectionVideo';

import { ReactComponent as Logo } from '../assets/projects/gentrification-forecast/logo.svg';
import { ReactComponent as DataAnalysis } from '../assets/projects/gentrification-forecast/data-analysis.svg';
import { ReactComponent as DataAnalysisMobile } from '../assets/projects/gentrification-forecast/data-analysis-mobile.svg';

import video from '../assets/projects/gentrification-forecast/cover.mp4';
import poster from '../assets/projects/gentrification-forecast/cover.jpg';
import previewVideo from '../assets/projects/gentrification-forecast/preview.mp4';
import previewPoster from '../assets/projects/gentrification-forecast/preview.jpg';
import shoreditch from '../assets/projects/gentrification-forecast/shoreditch.jpg';

const ProjectGentrificationForecast = () => {
  return (
    <Cover
      id="gentrification-forecast"
      logo={<Logo />}
      tagline="Predicting gentrification in London"
      video={{ url: video, poster: poster.src }}
      client="signal-noise"
    >
      <SectionProjectInfo
        rows={[
          ['Agency', 'Self initiated, Signal Noise, CLEVER°FRANKE'],
          ['Year', '2017'],
          ['Technology', 'NodeJS, WebGL, ThreeJS, D3, Electron'],
          ['Role', 'Concepting, analysis, development'],
        ]}
      />
      <SectionQuote quote="Experimenting with predictions by combining data sets together in an unordinary visual, made for an exhibition of Signal Noise in London" />
      <SectionColumns
        left={{
          heading: 'Problem',
          content:
            'For Signal Noise’s 2016 exhibition ‘Place your Bets’ in London I teamed up with Nick Ingram to made a piece together. Gentrification is a hot topic in London currently. Most people see Shoreditch, the place where the exhibition took place, as the most gentrified area in London, and is seen by some as being over-gentrified. We, therefore, asked ourselves the question: “We all know that Shoreditch is full of hipsters, but where will they migrate to next?”.',
        }}
        right={{
          heading: 'Solution',
          content:
            'To predict gentrification in London we need to predict the sweet spot for areas that are currently deprived, but are still diverse so ‘pioneers’ feel welcome. So to predict gentrification deprivation data should be combined with diversity data. Gentrification could be seen like pressure zones in weather. People are attracted to low-pressure zones until that area has too much pressure itself and people move on to next areas. Fluid simulations are therefore used to predict future patterns. Since wind patterns have been used to predict the future of gentrification, it makes sense to visualise gentrification as wind patterns too.',
        }}
      />
      <SectionImage
        heading="Gentrification"
        content={[
          'When “urban renewal” of lower class neighbourhoods with condos attracts yuppie tenants, driving up rents and driving out long-time and lower-income residents. It often begins with influxes of local artists looking for a cheap place to live, giving the neighbourhood a bohemian flair. This hip reputation attracts yuppies who want to live in such an atmosphere, driving out the lower income artists and lower income residents, often ethnic/racial minorities, changing the social character of the neighbourhood.',
          'It also involves the “yuppification” of local businesses; shops catering to yuppie tastes like sushi restaurants, Starbucks, etc… come to replace local businesses displaced by higher rents.',
          '(via Urban Dictionary)',
        ]}
        image={shoreditch}
        alt="Shoreditch is a well-known gentrified neighbourhood in London"
      />
      <SectionChart
        heading="Data analysis"
        content="These visualisations are created using the following processes to convert data in 3d graphics"
        chart={{ component: DataAnalysis, width: 1222, height: 288 }}
        chartMobile={{ component: DataAnalysisMobile, width: 761, height: 522 }}
      />
      <SectionVideo
        video={{
          src: previewVideo,
          poster: previewPoster.src,
          width: 1288,
          height: 712,
        }}
      />
      <SectionQuote
        heading="Lesson learned"
        quote="By looking with a fresh eye to data analysis you can find new refreshing insights and methods of visualising data."
      />
    </Cover>
  );
};

export default ProjectGentrificationForecast;
