import React from 'react';
import styled from 'styled-components';

import TextAnimationBlur from './TextAnimationBlur';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

const Container = styled(Grid.Container)`
  margin-top: ${SPACING(18)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
`;

type InsideProps = {
  heading?: boolean;
};

const Inside = styled(Grid.Cell)<InsideProps>`
  display: flex;
  gap: ${SPACING(6)}px;

  ${BREAKPOINTS.max.small`
    flex-direction: column;
    display: ${(props) => (props.heading ? 'none' : 'flex')};
  `}
`;

const Heading = styled.h3`
  ${TYPO.h3}
  margin-bottom: ${SPACING(2)}px;
`;

const MobileHeading = styled(Heading)`
  ${BREAKPOINTS.min.small`
    display: none;
  `}
`;

const Content = styled.div`
  ${TYPO.paragraph}
  display: flex;
  flex-direction: column;
  gap: 22px;

  p {
    margin: 0;
  }
`;

const Section = styled.div`
  width: 50%;

  ${BREAKPOINTS.max.small`
    width: 100%;
  `}
`;

type Column = {
  heading?: string;
  content: string | string[];
};

type SectionColumnsProps = {
  left: Column;
  right: Column;
};

const SectionColumns = ({ left, right }: SectionColumnsProps) => {
  return (
    <Container>
      <Inside start={2} columns={10} heading>
        <Section>
          <Heading>
            <TextAnimationBlur>{left.heading}</TextAnimationBlur>
          </Heading>
        </Section>
        <Section>
          <Heading>
            <TextAnimationBlur delay={0.2}>{right.heading}</TextAnimationBlur>
          </Heading>
        </Section>
      </Inside>
      <Inside
        start={2}
        columns={10}
        responsive={{ small: { start: 2, columns: 10 } }}
      >
        <Section>
          <Content>
            <MobileHeading>
              <TextAnimationBlur>{left.heading}</TextAnimationBlur>
            </MobileHeading>
            {Array.isArray(left.content)
              ? left.content.map((c) => <p key={c.substring(0, 100)}>{c}</p>)
              : left.content}
          </Content>
        </Section>
        <Section>
          <Content>
            <MobileHeading>
              <TextAnimationBlur>{right.heading}</TextAnimationBlur>
            </MobileHeading>
            {Array.isArray(right.content)
              ? right.content.map((c) => <p key={c.substring(0, 100)}>{c}</p>)
              : right.content}
          </Content>
        </Section>
      </Inside>
    </Container>
  );
};

export default SectionColumns;
