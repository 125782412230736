import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';

import FixedContainer from './FixedContainer';
import VideoPlayer from './VideoPlayer';
// import Button, { ButtonVariant } from './Button';
import Watermark, { WatermarkProps } from './Watermark';

const Container = styled.div`
  position: relative;
  height: 100vh;
  min-height: 600px;
  width: 100%;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 16, 35, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

type VideoPlayerStyledProps = {
  opacity: number;
};

const VideoPlayerStyled = styled(VideoPlayer)<VideoPlayerStyledProps>`
  opacity: ${(props) => props.opacity || 1};
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.white.css};
  flex-direction: column;
`;

const Tagline = styled.div`
  ${TYPO.regular}
  color: ${COLORS.dim.css};
  margin-top: ${SPACING(1)}px;
  max-width: 320px;
  text-align: center;

  ${BREAKPOINTS.max.small`
  max-width: 80%;
  `}
`;

// const ReadButton = styled.div`
//   position: absolute;
//   bottom: ${SPACING(2)}px;
//   left: 0;
//   width: 100%;
//   display: flex;
//   justify-content: center;
// `;

type ProjectProps = {
  show: boolean;
};

const Project = styled.div<ProjectProps>`
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
  padding-bottom: ${(props) => (props.show ? SPACING(32) : 0)}px;

  ${BREAKPOINTS.max.small`
      padding-bottom: ${(props) => (props.show ? SPACING(16) : 0)}px;
  `}
`;

type CoverProps = {
  id: string;
  logo: React.ReactNode;
  video: {
    url: string;
    opacity?: number;
    poster: string;
    position?: 'center' | 'top' | 'bottom';
  };
  tagline: string;
  client?: WatermarkProps['logo'];
  children: React.ReactNode;
};

const Cover = ({ id, logo, tagline, video, client, children }: CoverProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(location.hash.replace('#', '') === id);
  }, [id]);

  return (
    <>
      <Container
        onClick={() => {
          setShow(true);
          location.hash = id;

          mixpanel.track('case', {
            id,
          });
        }}
      >
        <VideoPlayerStyled
          opacity={video.opacity}
          src={video.url}
          poster={video.poster}
          fallbackOnMobile
          position={video.position}
        />
        <Inner>
          {logo}
          <Tagline>{tagline}</Tagline>
        </Inner>
        <FixedContainer copy="Read full case" />
        {client && <Watermark logo={client} />}
        {/* <ReadButton>
          <Button variant={ButtonVariant.MICRO}>Read full case</Button>
        </ReadButton> */}
      </Container>
      <Project id={id} show={show}>
        {show && children}
      </Project>
    </>
  );
};

export default Cover;
