import React from 'react';

import Cover from './Cover';
import SectionProjectInfo from './SectionProjectInfo';
import SectionQuote from './SectionQuote';
import SectionColumns from './SectionColumns';
import SectionImageGallery, { ImageSize } from './SectionImageGallery';
import SectionCTA from './SectionCTA';

import { ReactComponent as Logo } from '../assets/projects/lick/logo.svg';

import video from '../assets/projects/lick/cover.mp4';
import poster from '../assets/projects/lick/cover.jpg';
import preview1 from '../assets/projects/lick/preview.jpeg';
import preview2Video from '../assets/projects/lick/preview-2.mp4';
import preview2Poster from '../assets/projects/lick/preview-2.jpg';
import preview3Video from '../assets/projects/lick/preview-3.mp4';
import preview3Poster from '../assets/projects/lick/preview-3.jpg';

const ProjectLick = () => {
  return (
    <Cover
      id="lick"
      logo={<Logo />}
      tagline="Creating a new home decor brand"
      video={{ url: video, poster: poster.src, opacity: 0.65 }}
      client="neverbland"
    >
      <SectionProjectInfo
        rows={[
          ['Agency', 'Neverbland'],
          ['Year', '2020'],
          ['Technology', 'ReactJS, NextJS, Apollo Federation'],
          ['Role', 'Full-stack development'],
        ]}
      />
      <SectionQuote quote="Helping the founders behind Lick set out to disrupt the industry and create a new age decor company that delivers straight to your doorstep." />
      <SectionColumns
        left={{
          heading: 'Problem',
          content: [
            "Only 4.5% of paint sales occur online, a market ripe for disruption by a new start-up. Traditional paint shops aren't very user friendly either. How do you even pick a colour in those vast colour ranges with questionable product names like Probiscus Fur or Croydon Rock? Lick worked closely with leading interior designers to define a small but perfectly formed colour range. Simply named by number. No more Silk Matt Mid Sheen Emulsion. Keep it simple – just Matt or Eggshell.",
            'At Neverbland I was responsible for setting up the tech stack for this new webshop. The goal was to go to the market as fast as possible with a platform that could later be built upon by an in-house development team.',
          ],
        }}
        right={{
          heading: 'Solution',
          content: [
            "Lick is a home decor brand that at launch focussed mainly on paint and wanted to do things differently from market norms. This meant that the design of this webshop had to be super slick and user friendly. Most off-the-shelf products provide very powerful technology to handle the back-end side of the operation, like integrations with fulfilment centres and payment handling. These products aren't very good at theming, they all feel very run of the mill and bland.",
            'A custom front-end based on React and NextJS was chosen so the webshop could meet the quality Lick wanted to offer their customers. Shopify matched their needs when it came to the back-end of the webshop, which was combined via Apollo Federation to Prismic which provided enhanced content editing capabilities for the whole website. Combine the two and what you get is the benefit of everything Shopify offers with the creative freedom of offering a carefully curated User Experience coupled with a blazing fast build.',
          ],
        }}
      />
      <SectionImageGallery
        images={[
          { src: preview1, size: ImageSize.FULL },
          {
            video: {
              url: preview2Video,
              poster: preview2Poster.src,
              width: 1080,
              height: 1080,
            },
            size: ImageSize.MEDIUM,
          },
          {
            video: {
              url: preview3Video,
              poster: preview3Poster.src,
              width: 1080,
              height: 1080,
            },
            size: ImageSize.MEDIUM,
          },
        ]}
      />{' '}
      <SectionQuote
        heading="Lesson learned"
        quote="By choosing the technology stack correctly a project can be delivered within time and budget while maintaining a high standard for quality and design. "
      />
      <SectionCTA
        heading="Visit"
        cta={{
          label: 'Open website',
          href: 'https://lickhome.com',
        }}
      />
    </Cover>
  );
};

export default ProjectLick;
