import React from 'react';
import styled from 'styled-components';
import Image, { ImageProps } from 'next/image';

import VideoPlayer from './VideoPlayer';
import AnimationZoom from './AnimationZoom';
import TextAnimationBlur from './TextAnimationBlur';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

const Container = styled.div`
  margin-top: ${SPACING(34)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
  text-align: center;
`;

const Heading = styled.h3`
  ${TYPO.h2}
  margin-bottom: ${SPACING(8)}px;
  color: ${COLORS.white.css};
`;

const Fullscreen = styled.div`
  width: 100%;
`;

const Medium = styled(Grid.Container)`
  margin-top: ${SPACING(4)}px;
`;

const MediumInner = styled(Grid.Cell)`
  display: flex;
  gap: ${SPACING(4)}px;

  ${BREAKPOINTS.max.small`
    flex-direction: column;
      gap: ${SPACING(2)}px;
  `}
`;

const MediumImage = styled.div`
  width: calc(50% - ${SPACING(2)}px);

  ${BREAKPOINTS.max.small`
    width: 100%;
  `}
`;

const Small = styled(Grid.Container)`
  margin-top: ${SPACING(4)}px;
`;

type VideoContainerProps = {
  width: number;
  height: number;
};

const VideoContainer = styled.div<VideoContainerProps>`
  position: relative;
  width: 100%;
  padding-top: ${(props) => (props.height / props.width) * 100}%;
`;

export enum ImageSize {
  FULL,
  MEDIUM,
  SMALL,
}

type Video = {
  url: string;
  poster: string;
  width: number;
  height: number;
};

type SectionImageGalleryProps = {
  heading?: string;
  images: {
    src?: ImageProps['src'];
    video?: Video;
    size: ImageSize;
  }[];
};

const SectionImageGallery = ({
  heading = 'Preview',
  images,
}: SectionImageGalleryProps) => {
  const fullscreen = images.filter((i) => i.size === ImageSize.FULL);
  const medium = images.filter((i) => i.size === ImageSize.MEDIUM);
  const small = images.filter((i) => i.size === ImageSize.SMALL);
  return (
    <>
      <Container>
        <Heading>
          <TextAnimationBlur>{heading}</TextAnimationBlur>
        </Heading>
      </Container>
      {fullscreen.length > 0 && (
        <Fullscreen>
          {fullscreen.map((image) => (
            <AnimationZoom key={JSON.stringify(image.src)}>
              <Image src={image.src} layout="responsive" alt="" />
            </AnimationZoom>
          ))}
        </Fullscreen>
      )}
      <Medium>
        <MediumInner start={1} columns={12}>
          {medium.map((image) => (
            <MediumImage
              key={image.video ? image.video.url : JSON.stringify(image.src)}
            >
              {image.video ? (
                <AnimationZoom>
                  <VideoContainer
                    width={image.video.width}
                    height={image.video.height}
                  >
                    <VideoPlayer
                      src={image.video.url}
                      poster={image.video.poster}
                    />
                  </VideoContainer>
                </AnimationZoom>
              ) : (
                <AnimationZoom>
                  <Image src={image.src} layout="responsive" alt="" />
                </AnimationZoom>
              )}
            </MediumImage>
          ))}
        </MediumInner>
      </Medium>
      <Small>
        {small.map((image) => (
          <Grid.Cell
            columns={4}
            responsive={{
              small: {
                start: null,
                columns: 12,
              },
            }}
            key={JSON.stringify(image.src)}
          >
            <AnimationZoom>
              <Image src={image.src} layout="responsive" alt="" />
            </AnimationZoom>
          </Grid.Cell>
        ))}
      </Small>
    </>
  );
};

export default SectionImageGallery;
