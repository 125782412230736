import React from 'react';
import styled from 'styled-components';

import TextAnimationBlur from './TextAnimationBlur';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

const Container = styled(Grid.Container)`
  margin-top: ${SPACING(18)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
`;

type InsideProps = {
  heading?: boolean;
};

const Inside = styled(Grid.Cell)<InsideProps>`
  display: flex;
  gap: ${SPACING(6)}px;

  ${BREAKPOINTS.max.small`
    flex-direction: column;
    display: ${(props) => (props.heading ? 'none' : 'flex')};
  `}
`;

const Heading = styled.h3`
  ${TYPO.h3}
`;

const Content = styled.div`
  ${TYPO.paragraph}
  display: flex;
  flex-direction: column;
  gap: 22px;

  p {
    margin: 0;
  }
`;

type SectionColumnProps = {
  heading: string;
  content: string | string[];
};

const SectionColumn = ({ heading, content }: SectionColumnProps) => {
  return (
    <Container>
      <Inside
        start={4}
        columns={6}
        responsive={{ small: { start: 2, columns: 10 } }}
      >
        <Content>
          <Heading>
            <TextAnimationBlur>{heading}</TextAnimationBlur>
          </Heading>
          {Array.isArray(content)
            ? content.map((c) => <p key={c.substring(0, 100)}>{c}</p>)
            : content}
        </Content>
      </Inside>
    </Container>
  );
};

export default SectionColumn;
