import React from 'react';
import styled from 'styled-components';
import Image, { ImageProps } from 'next/image';

import VideoPlayer from './VideoPlayer';
import TextAnimationBlur from './TextAnimationBlur';
import AnimationZoom from './AnimationZoom';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

const Container = styled(Grid.Container)`
  margin-top: ${SPACING(28)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
`;

const Images = styled(Grid.Cell)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING(2)}px;

  ${BREAKPOINTS.max.small`
    grid-row: 2;
    margin-bottom: ${SPACING(4)}px;
  `}
`;

const Content = styled(Grid.Cell)`
  ${TYPO.paragraph}

  h3 {
    ${TYPO.h3}
    margin-bottom: ${SPACING(2)}px;
  }
`;

const ContentInside = styled.div`
  position: sticky;
  top: ${SPACING(2)}px;

  ${BREAKPOINTS.max.small`
    margin-top: ${SPACING(0)}px;
        margin-bottom: ${SPACING(4)}px;

  `}
`;

type VideoContainerProps = {
  width: number;
  height: number;
};

const VideoContainer = styled.div<VideoContainerProps>`
  position: relative;
  width: 100%;
  padding-top: ${(props) => (props.height / props.width) * 100}%;
`;

type Video = {
  url: string;
  poster: string;
  width: number;
  height: number;
};

type SectionImageStickyProps = {
  images: Array<ImageProps['src'] | Video>;
  heading: string;
  content: string;
  alt: string;
};

const SectionImageSticky = ({
  heading,
  content,
  images,
  alt,
}: SectionImageStickyProps) => {
  return (
    <Container>
      <Images
        start={2}
        columns={5}
        responsive={{ small: { start: 2, columns: 10 } }}
      >
        {images.map((image) =>
          typeof image === 'object' && 'poster' in image ? (
            <AnimationZoom>
              <VideoContainer width={image.width} height={image.height}>
                <VideoPlayer src={image.url} poster={image.poster} />
              </VideoContainer>
            </AnimationZoom>
          ) : (
            <AnimationZoom>
              <Image
                src={image}
                layout="responsive"
                key={JSON.stringify(image)}
                alt={alt}
              />
            </AnimationZoom>
          )
        )}
      </Images>
      <Content
        start={8}
        columns={4}
        responsive={{ small: { start: 2, columns: 10 } }}
      >
        <ContentInside>
          <h3>
            <TextAnimationBlur>{heading}</TextAnimationBlur>
          </h3>
          {content}
        </ContentInside>
      </Content>
    </Container>
  );
};

export default SectionImageSticky;
