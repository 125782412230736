import React from 'react';
import styled from 'styled-components';

import TextAnimationBlur from './TextAnimationBlur';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

const Container = styled(Grid.Container)`
  padding-top: ${SPACING(18)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
`;

const Heading = styled(Grid.Cell)`
  ${TYPO.h3};

  ${BREAKPOINTS.max.small`
    margin-bottom: ${SPACING(4)}px;
  `}
`;

const Content = styled.div`
  ${Grid.grid(6)}
  ${Grid.columns(null, 6)}

    ${BREAKPOINTS.max.small`
      ${Grid.grid(12)}
      ${Grid.columns(null, 12)}
  `}
`;

const RowName = styled.div`
  ${Grid.columns(null, 2)}
  ${TYPO.h4}
  margin-bottom: ${SPACING(1.5)}px;

  ${BREAKPOINTS.max.small`
    ${Grid.columns(2, 10)}
  `}
`;

const RowContent = styled.div`
  ${Grid.columns(null, 4)}
  ${TYPO.regular}
  margin-bottom: ${SPACING(1.5)}px;

  ${BREAKPOINTS.max.small`
    ${Grid.columns(2, 10)}
  `}
`;

type SectionProjectInfoProps = {
  heading?: string;
  rows: Array<[string, string]>;
};

const SectionProjectInfo = ({
  heading = 'Project info',
  rows,
}: SectionProjectInfoProps) => {
  return (
    <Container>
      <Heading
        start={2}
        columns={4}
        responsive={{
          small: {
            start: 2,
            columns: 10,
          },
        }}
      >
        <TextAnimationBlur>{heading}</TextAnimationBlur>
      </Heading>
      <Content>
        {rows.map((row) => (
          <>
            <RowName>{row[0]}</RowName>
            <RowContent>{row[1]}</RowContent>
          </>
        ))}
      </Content>
    </Container>
  );
};

export default SectionProjectInfo;
