import React from 'react';
import styled from 'styled-components';

import VideoPlayer from './VideoPlayer';
import TextAnimationBlur from './TextAnimationBlur';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';

const Container = styled.div`
  margin-top: ${SPACING(34)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
  text-align: center;
`;

const Heading = styled.h3`
  ${TYPO.h2}
  margin-bottom: ${SPACING(8)}px;
  color: ${COLORS.white.css};
`;

type VideoContainerProps = {
  width: number;
  height: number;
};

const VideoContainer = styled.div<VideoContainerProps>`
  position: relative;
  width: 100%;
  padding-top: ${(props) => (props.height / props.width) * 100}%;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

type SectionVideoProps = {
  heading?: string;
  video: {
    src: string;
    poster: string;
    width: number;
    height: number;
  };
};

const SectionVideo = ({ heading = 'Preview', video }: SectionVideoProps) => {
  return (
    <>
      <Container>
        <Heading>
          <TextAnimationBlur>{heading}</TextAnimationBlur>
        </Heading>
      </Container>
      <VideoContainer width={video.width} height={video.height}>
        <VideoPlayer src={video.src} poster={video.poster} />
      </VideoContainer>
    </>
  );
};

export default SectionVideo;
