import React from 'react';
import styled from 'styled-components';

import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';

import * as Grid from '../styles/grid';

import Button from './Button';

const Container = styled(Grid.Container)`
  margin-top: ${SPACING(20)}px;
  padding-bottom: ${SPACING(40)}px;
`;

const Heading = styled(Grid.Cell)`
  h2 {
    ${TYPO.h3};
  }
`;

const Content = styled(Grid.Cell)`
  ${TYPO.regular2}
`;

const Buttons = styled.div`
  display: flex;
  margin-top: ${SPACING(1.5)}px;
  gap: ${SPACING(1)}px;

  ${BREAKPOINTS.max.small`
    flex-direction: column;
  `}
`;

const Splash = () => {
  return (
    <Container id="contact">
      <Heading
        start={2}
        columns={4}
        responsive={{ small: { start: 2, columns: 10 } }}
      >
        <h2>Contact</h2>
      </Heading>
      <Content columns={5} responsive={{ small: { start: 2, columns: 10 } }}>
        Feel free to contact me, I am always up for having a chat
        <Buttons>
          <Button link={{ href: 'mailto:davey@dpwoert.com' }}>Email</Button>
          <Button
            link={{ href: 'https://twitter.com/dpwoert', target: '_blank' }}
          >
            Twitter
          </Button>
          <Button
            link={{ href: 'https://github.com/dpwoert', target: '_blank' }}
          >
            Github
          </Button>
          <Button
            link={{
              href: 'https://www.linkedin.com/in/dpwoert/',
              target: '_blank',
            }}
          >
            Linkedin
          </Button>
        </Buttons>
      </Content>
    </Container>
  );
};

export default Splash;
