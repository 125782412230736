import React from 'react';

import Cover from './Cover';
import SectionProjectInfo from './SectionProjectInfo';
import SectionQuote from './SectionQuote';
import SectionColumns from './SectionColumns';
import SectionVideoGrid from './SectionVideoGrid';
import SectionMagicCircleFrameworks from './SectionMagicCircleFrameworks';
import SectionMagicCircleFeatures from './SectionMagicCircleFeatures';

import { ReactComponent as Logo } from '../assets/projects/magic-circle/logo.svg';

import video from '../assets/projects/magic-circle/cover.mp4';
import poster from '../assets/projects/magic-circle/cover.jpg';
import reelVideo from '../assets/projects/magic-circle/editor.mp4';
import reelPoster from '../assets/projects/magic-circle/editor.jpg';

const ProjectMagicCircle = () => {
  return (
    <Cover
      id="magic-circle"
      logo={<Logo />}
      tagline="My open source editor for creative coding projects"
      video={{ url: video, poster: poster.src, opacity: 1, position: 'top' }}
    >
      <SectionProjectInfo
        rows={[
          ['Agency', 'Self-initiated'],
          ['Year', '2022-2024'],
          ['Technology', 'ReactJS, typescript, NPM, ThreeJS, P5'],
          ['Role', 'Design, development'],
        ]}
      />
      <SectionQuote quote="Born out of my own need, I’ve created an editor that can quickly be added to creative coding projects and provide powerful tools to enable discovery " />
      <SectionColumns
        left={{
          heading: 'Problem',
          content:
            "I always need extra tooling while developing creative coding projects. It's always hard and time consuming to make my own UIs for every project.  need something that I can setup quickly and has an extensive set of tools that I can customise for my needs. Existing tools are often too light or too hard and bespoke to setup.",
        }}
        right={{
          heading: 'Solution',
          content: [
            "Magic Circle is my attempt at creating that tool for my creative development needs. It's a multi-functional user interface inspired by tools like dat.GUI, Unity and Framer. Think Storybook.js, but then for creative coding. It is a very small bit of code (~5kb, no external dependencies) to add to a project but it allows me to create a fully extensible user interface with tools to play with for these  project.",
            "The aim of this tool is not only to allow for easier and quicker development but also to promote collaboration and playfulness inside the teams I’m working with. Everyone should be able to play with the tech you make and add a meaningful contribution. You don't need to be a coder to improve a piece of creative tech. With this tool people can play with your project and share their results.",
            'I named this tool Magic Circle, which according to Huizinga (Homo Ludens, 1938) is the place where play takes place. A place whereby the rules and reality that guard normal life have been suspended',
          ],
        }}
      />
      <SectionVideoGrid
        heading="Preview"
        video={{
          src: reelVideo,
          poster: reelPoster.src,
          width: 1301,
          height: 720,
        }}
      />
      <SectionMagicCircleFrameworks />
      <SectionMagicCircleFeatures />
      <SectionQuote
        heading="Lesson learned"
        quote="Its possible, and needed, to create your own tooling so that you can improve the quality, efficiency and collaboration of the projects you work on. "
      />
    </Cover>
  );
};

export default ProjectMagicCircle;
