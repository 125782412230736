import React from 'react';
import styled from 'styled-components';

import VideoPlayer from './VideoPlayer';
import TextAnimationBlur from './TextAnimationBlur';

import * as Grid from '../styles/grid';
import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';

const Container = styled(Grid.Container)`
  margin-top: ${SPACING(34)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
  text-align: center;
`;

const Heading = styled.h3`
  ${TYPO.h2}
  margin-bottom: ${SPACING(8)}px;
  color: ${COLORS.white.css};
`;

type VideoContainerProps = {
  width: number;
  height: number;
};

const VideoContainer = styled.div<VideoContainerProps>`
  position: relative;
  width: 100%;
  padding-top: ${(props) => (props.height / props.width) * 100}%;
  border-radius: 5px;
  overflow: hidden;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

type SectionVideoProps = {
  heading?: string;
  video: {
    src: string;
    poster: string;
    width: number;
    height: number;
  };
};

const SectionVideoGrid = ({
  heading = 'Preview',
  video,
}: SectionVideoProps) => {
  return (
    <Container>
      <Grid.Cell start={0} columns={12}>
        <Heading>
          <TextAnimationBlur>{heading}</TextAnimationBlur>
        </Heading>
      </Grid.Cell>
      <Grid.Cell
        start={2}
        columns={10}
        responsive={{ small: { start: 1, columns: 12 } }}
      >
        <VideoContainer width={video.width} height={video.height}>
          <VideoPlayer src={video.src} poster={video.poster} />
        </VideoContainer>
      </Grid.Cell>
    </Container>
  );
};

export default SectionVideoGrid;
