import React from 'react';
import styled from 'styled-components';
import Image, { ImageProps } from 'next/image';

import AnimationZoom from './AnimationZoom';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import BREAKPOINTS from '../styles/breakpoints';
import * as Grid from '../styles/grid';

const Container = styled(Grid.Container)`
  margin-top: ${SPACING(28)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
`;

const Heading = styled(Grid.Cell)`
  ${TYPO.h2}
  margin-bottom: ${SPACING(7)}px;
  text-align: center;

  ${BREAKPOINTS.max.small`
          ${TYPO.h3}
      `}
`;

const Content = styled(Grid.Cell)`
  ${TYPO.paragraph}
  display: flex;
  flex-direction: column;
  gap: 22px;
  justify-content: center;

  p {
    margin: 0;
  }

  ${BREAKPOINTS.max.small`
          margin-top: ${SPACING(4)}px;
      `}
`;

type SectionImageProps = {
  image: ImageProps['src'];
  heading: string;
  content: string | string[];
  alt: string;
};

const SectionImage = ({ heading, content, image, alt }: SectionImageProps) => {
  return (
    <Container>
      <Heading start={2} columns={10}>
        {heading}
      </Heading>
      <Grid.Cell
        start={2}
        columns={5}
        responsive={{ small: { start: 2, columns: 10 } }}
      >
        <AnimationZoom>
          <Image src={image} layout="responsive" alt={alt} />
        </AnimationZoom>
      </Grid.Cell>
      <Content
        start={8}
        columns={4}
        responsive={{ small: { start: 2, columns: 10 } }}
      >
        {Array.isArray(content)
          ? content.map((c) => <p key={c.substring(0, 100)}>{c}</p>)
          : content}
      </Content>
    </Container>
  );
};

export default SectionImage;
