import React from 'react';
import styled from 'styled-components';
import Image, { ImageProps } from 'next/image';

import COLORS from '../styles/colors';
import SPACING from '../styles/spacing';

const Container = styled.div`
  margin-top: ${SPACING(9)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
  width: 100%;
`;

type SectionImageFullscreenProps = {
  image: ImageProps['src'];
  alt: string;
};

const SectionImageFullscreen = ({
  image,
  alt,
}: SectionImageFullscreenProps) => {
  return (
    <Container>
      <Image src={image} layout="responsive" alt={alt} />
    </Container>
  );
};

export default SectionImageFullscreen;
