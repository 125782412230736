import React from 'react';

import Cover from './Cover';
import SectionProjectInfo from './SectionProjectInfo';
import SectionQuote from './SectionQuote';
import SectionColumns from './SectionColumns';
import SectionChart from './SectionChart';
import SectionVideo from './SectionVideo';

import { ReactComponent as Logo } from '../assets/projects/webar/logo.svg';
import { ReactComponent as Setup } from '../assets/projects/webar/process.svg';
import { ReactComponent as SetupMobile } from '../assets/projects/webar/process-mobile.svg';

import video from '../assets/projects/webar/cover.mp4';
import poster from '../assets/projects/webar/cover.jpg';
import reelVideo from '../assets/projects/webar/reel.mp4';
import reelPoster from '../assets/projects/webar/reel.jpg';

const ProjectWebAR = () => {
  return (
    <Cover
      id="webar"
      logo={<Logo />}
      tagline="Leading the development on multiple media campaigns"
      video={{ url: video, poster: poster.src, opacity: 0.5 }}
      client="omm"
    >
      <SectionProjectInfo
        rows={[
          ['Agency', 'OMM'],
          ['Year', '2023'],
          ['Technology', 'WebGL, ThreeJS, Niantic 8th Wall, Volumetric video'],
          ['Clients', 'Nike, JD Sports, Tommy Hilfiger, Aries'],
        ]}
      />
      <SectionQuote quote="I’ve lead the development of a series of media campaigns for various brands using Augmented Reality for the web at OMM" />
      <SectionColumns
        left={{
          heading: 'Problem',
          content: [
            'Lots of brands are increasingly leveraging augmented reality (AR) to elevate their marketing campaigns and create immersive brand experiences. AR technology allows companies to engage consumers in novel and interactive ways. It has become a powerful tool for storytelling whereby narratives can go beyond traditional advertising methods.',
            'OMM is an agency focussing on sport and fashion clients and thus had started pitching Augmented Reality campaigns to their clients. The challenge then became how can we make this a reality in a world where everybody is fighting for attention? This especially due to the fact that people aren’t going to spend time installing native apps to their phone... Next to that, these campaigns tend to be rushed against a hard deadline and with lot of last minutes requests and changes coming in.',
          ],
        }}
        right={{
          heading: 'Solution',
          content: [
            'I’ve lead OMM in creating a great, and repeatable, setup for WebAR projects. Leveraging Niantic 8th Wall, ThreeJS and React for pushing the most out of phones on the web. The advantage of the web is that users won’t have to install anything on their phone, allowing them to instantly dive into an experience. On the other hand, the performance of 3D on the web will never be as good as a natively written app. This means performance constantly had to be pushed, and a process was needed where by could make the right trade-offs and compromises for the best end results.',
            'An example of an experience I’ve lead at OMM is the takeover of Erling Halaand at NikeTown, London, announcing him as the new "Nike No. 9". In this experience passersby could see Haaland in his well-known "Buddha" pose. To achieve this, we organised and produced a volumetric photoshoot to capture a 3D mesh of Haaland while pushing the 8th Wall technology to have him appear on top of the building.',
          ],
        }}
      />
      <SectionChart
        heading="Setup"
        content="This is a rough outline of the technical setup created for these projects"
        chart={{ component: Setup, width: 1383, height: 197 }}
        chartMobile={{
          component: SetupMobile,
          width: 650,
          height: 648,
          small: false,
        }}
      />
      <SectionVideo
        heading="Reel"
        video={{
          src: reelVideo,
          poster: reelPoster.src,
          width: 1280,
          height: 720,
        }}
      />
      <SectionQuote
        heading="Lesson learned"
        quote="By creating a repeatable setup and a good process its possible to push creative technology over multiple media campaigns."
      />
    </Cover>
  );
};

export default ProjectWebAR;
