/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { easeOutQuad } from '../utils/easings';

import useInview from '../hooks/useInview';

const animation = keyframes`
  0%{
    transform: translateY(150px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;

const Mask = styled.span`
  display: inline-block;
  clip-path: inset(0px 0px -10px 0px);
`;

type WordProps = {
  show: boolean;
};

const Word = styled.span<WordProps>`
  display: inline-block;
  opacity: 0;
  white-space: pre;
  ${(props) =>
    props.show &&
    css`
      animation: ${animation} 0.4s ease-out;
      animation-fill-mode: both;
    `};
`;

type TextAnimationTransformProps = {
  children: string;
  duration?: number;
};

const TextAnimationTransform = ({
  children,
  duration = 200,
  ...props
}: TextAnimationTransformProps) => {
  const [ref, inView] = useInview({ once: true });

  if (!children) {
    // sanity check
    return null;
  }

  const words = children.split(' ');

  return (
    <span ref={ref}>
      {words.map((word, i) => (
        <Mask key={`${word}_${i}`} {...props}>
          <Word
            key={`${word}_${i}`}
            style={{
              animationDelay: `${duration * easeOutQuad(i / words.length)}ms`,
            }}
            show={inView}
          >
            {word}{' '}
          </Word>
          {i < words.length - 1 ? ' ' : null}
        </Mask>
      ))}
    </span>
  );
};

export default TextAnimationTransform;
