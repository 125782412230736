import React from 'react';

import Cover from './Cover';
import SectionProjectInfo from './SectionProjectInfo';
import SectionQuote from './SectionQuote';
import SectionColumns from './SectionColumns';
import SectionColumn from './SectionColumn';
import SectionVideo from './SectionVideo';
import SectionImageSticky from './SectionImageSticky';
import SectionCTA from './SectionCTA';
import SectionImageGallery, { ImageSize } from './SectionImageGallery';

import { ReactComponent as Logo } from '../assets/projects/flow/logo.svg';

import video from '../assets/projects/flow/cover-compressed.mp4';
import poster from '../assets/projects/flow/cover.jpg';
import websiteVideo from '../assets/projects/flow/website.mp4';
import websitePoster from '../assets/projects/flow/website.jpg';
import prototype1Video from '../assets/projects/flow/prototype-1.mp4';
import prototype1Poster from '../assets/projects/flow/prototype-1.jpg';
import prototype2Video from '../assets/projects/flow/prototype-2.mp4';
import prototype2Poster from '../assets/projects/flow/prototype-2.jpg';
import prototype3Video from '../assets/projects/flow/prototype-3.mp4';
import prototype3Poster from '../assets/projects/flow/prototype-3.jpg';
import prototype4 from '../assets/projects/flow/prototype-4.png';
import preview1 from '../assets/projects/flow/preview-1.png';
import preview2 from '../assets/projects/flow/preview-2.png';
import preview3 from '../assets/projects/flow/preview-3.png';
import ui1 from '../assets/projects/flow/ui-1.png';
import ui2 from '../assets/projects/flow/ui-2.png';
import ui3 from '../assets/projects/flow/ui-3.png';
import ui4 from '../assets/projects/flow/ui-4.png';
import ui5 from '../assets/projects/flow/ui-5.png';
import ui6 from '../assets/projects/flow/ui-6.png';

const ProjectFlow = () => {
  return (
    <Cover
      id="flow"
      logo={<Logo />}
      tagline="Reinventing mechanical engineering"
      video={{ url: video, poster: poster.src, opacity: 0.65 }}
    >
      <SectionProjectInfo
        rows={[
          ['Company', 'Flow / The Engineering Company'],
          ['Year', '2020-2022'],
          ['Technology', 'ReactJS, NextJS, Electron'],
          ['Role', 'Product, design, front-end development'],
        ]}
      />
      <SectionQuote quote="Helping revolutionise how hardware is designed and developed to unlock a new generation of engineering solutions." />
      <SectionColumns
        left={{
          heading: 'Problem',
          content: [
            'Mechanical engineering has become slow, expensive and painful. The complexity of projects has rosed exponentially but the processes and mechanisms to support these projects have not changed much since the heydays of engineering. What used to feel like innovation and invention, now feels like a grind. Simple changes can take days to propagate to other parts of a product team, thus it becomes extremely hard to keep the whole team on the same page.',
            'My role was to lead the front-end and design of this early-stage startup. When I joined the company just started coming out of an R&D phase, whereby I had to set up the front-end team and set up a design culture that allows the team to make great product prototypes.',
          ],
        }}
        right={{
          heading: 'Solution',
          content:
            'We ended up with what can be described as "GitHub for hardware". It uses learnings of software development like a single source of truth, version control and continuous verification & deployment to create a new paradigm for hardware engineering. Flow lets users easily change control engineering requirements, values and verification statuses without spreadsheets, meetings and emails. By notifying the right people when and how a change affects them engineering iterations can be massively accelerated.',
        }}
      />
      <SectionVideo
        video={{
          src: websiteVideo,
          poster: websitePoster.src,
          width: 2944,
          height: 1672,
        }}
        heading="Website"
      />
      <SectionImageSticky
        heading="Prototype"
        images={[
          {
            url: prototype1Video,
            poster: prototype1Poster.src,
            width: 1920,
            height: 1040,
          },
          {
            url: prototype2Video,
            poster: prototype2Poster.src,
            width: 2800,
            height: 1536,
          },
          {
            url: prototype3Video,
            poster: prototype3Poster.src,
            width: 2162,
            height: 1436,
          },
          prototype4,
        ]}
        alt=""
        content="The first product I helped develop used visual programming to connect engineering models together quickly. Visual programming offers the flexibility of coding for people who haven't learned a programming language. I was responsible for the design and the front-end code of this prototype. The back-end was running on Python and GoLang so our software had to be desktop-based. We opted for an Electron-based approach so we could combine the flexibility of web development with the power of a more traditional back-end running locally. By making this prototype and sharing this with potential clients we learned a tremendous amount of insights which eventually led us to pivot to a more project-based instead of model-based approach."
      />
      <SectionColumn
        heading="Market fit"
        content={`The previous model-based approach wasn't scalable, therefor I helped crystalise the new project-based approach. Hereby we no longer focussed on connecting everything together on a visual programming "canvas" but rather on letting users create an interconnected graph. This graph connected values, requirements and models in a collaborative cloud environment. Since everything is part of version control with change requests, the system can do smart things like impact analysis. This can tell the user the wider impact of their changes.`}
      />
      <SectionImageGallery
        images={[
          { src: preview1, size: ImageSize.FULL },
          { src: preview2, size: ImageSize.MEDIUM },
          { src: preview3, size: ImageSize.MEDIUM },
          { src: ui1, size: ImageSize.SMALL },
          { src: ui2, size: ImageSize.SMALL },
          { src: ui3, size: ImageSize.SMALL },
          { src: ui4, size: ImageSize.SMALL },
          { src: ui5, size: ImageSize.SMALL },
          { src: ui6, size: ImageSize.SMALL },
        ]}
      />
      <SectionQuote
        heading="Lesson learned"
        quote="By creating a design culture it is possible to find a market fit for a technology-centric early-stage startup"
      />
      <SectionCTA
        heading="Visit"
        cta={{
          label: 'Open website',
          href: 'https://flowengineering.com',
        }}
      />
    </Cover>
  );
};

export default ProjectFlow;
