import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';

import SPACING from '../styles/spacing';

import { ReactComponent as SN } from '../assets/logos/white/signal-noise.svg';
import { ReactComponent as Neverbland } from '../assets/logos/white/neverbland.svg';
import omm from '../assets/logos/black/omm.jpeg';

const Container = styled.div`
  position: absolute;
  bottom: ${SPACING(2)}px;
  right: ${SPACING(2)}px;
`;

export type WatermarkProps = {
  logo: 'neverbland' | 'signal-noise' | 'cleverfranke' | 'omm';
};

const Watermark = ({ logo }: WatermarkProps) => {
  return (
    <Container>
      {logo === 'signal-noise' && <SN width="108" height="24" />}
      {logo === 'neverbland' && <Neverbland width="136" height="18" />}
      {logo === 'omm' && (
        <Image src={omm} alt="OMM logo" width={65} height={24} />
      )}
    </Container>
  );
};

export default Watermark;
