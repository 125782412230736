import React from 'react';
import styled, { css } from 'styled-components';

import SPACING from './spacing';
import BREAKPOINTS, { Breakpoint } from './breakpoints';

// export const GRID_WIDTH = SPACING(106);
// export const GRID_MIN_WIDTH = SPACING(92);
export const GRID_MAX_WIDTH = SPACING(164);
export const COLUMNS = 12;
export const GUTTER = SPACING(2);
export const GUTTER_SMALL = SPACING(1);

export const columns = (start: number, cols: number) =>
  start
    ? css`
        grid-column: col-start ${start} / span ${cols};
      `
    : css`
        grid-column: span ${cols};
      `;

export const grid = (col: number) => css`
  display: grid;
  grid-template-columns: repeat(${col}, [col-start] 1fr);
  column-gap: ${SPACING(2)}px;
`;

// detect if background is image
const getBackground = (background) =>
  background.toLowerCase().indexOf('http') > -1 ||
  background.toLowerCase().indexOf('png') > -1 ||
  background.toLowerCase().indexOf('jpg') > -1 ||
  background.toLowerCase().indexOf('svg') > -1
    ? `url(${background})`
    : background;

type GridOuterProps = {
  background?: string;
  outerGutter: boolean;
};

export const Outer = styled.div<GridOuterProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ background }) =>
    background ? getBackground(background) : 'none'};
  background-size: cover;
  background-position: center center;
  padding: 0 ${(props) => (props.outerGutter ? GUTTER : 0)}px;

  ${BREAKPOINTS.max.medium`
    padding: 0 ${GUTTER_SMALL}px;
  `}
`;

type InnerProps = {
  columns?: number;
  dense?: boolean;
};

export const Inner = styled.div<InnerProps>`
  position: relative;
  max-width: ${GRID_MAX_WIDTH}px;
  width: 100%;
  grid-auto-flow: ${(props) => (props.dense === false ? 'initial' : 'dense')};
  ${(props) => grid(props.columns || COLUMNS)}
`;

type CellProps = {
  start?: number;
  columns: number;
  hide?: boolean;
  responsive?: {
    [key in Breakpoint]?: {
      start: number;
      columns: number;
      hide?: boolean;
    };
  };
};

const renderCell = (responsive: CellProps['responsive']) => {
  return Object.values(Breakpoint)
    .map((key) => {
      if (responsive[key]) {
        return BREAKPOINTS.max[key]`
        ${columns(responsive[key].start, responsive[key].columns)}

        ${
          responsive[key].hide &&
          css`
            display: none;
          `
        }}

      `;
      }

      return null;
    })
    .filter((i) => !!i);
};

// ${(props) =>
//   props.hide
//     ? css`
//         display: none;
//       `
//     : undefined}

export const Cell = styled.div<CellProps>`
  ${(props) => columns(props.start, props.columns)}
  ${(props) => (props.responsive ? renderCell(props.responsive) : null)}
`;

type GridProps = {
  id?: string;
  children: React.ReactNode;
  className?: string;
  background?: string;
  columns?: number;
  dense?: boolean;
  outerGutter?: boolean;
};

export const Container = ({
  children,
  className,
  background,
  columns,
  dense = true,
  outerGutter = true,
  ...props
}: GridProps) => (
  <Outer
    outerGutter={outerGutter}
    background={background}
    {...props}
    className={className}
  >
    <Inner columns={columns} dense={dense}>
      {children}
    </Inner>
  </Outer>
);
