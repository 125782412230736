import React from 'react';
import styled from 'styled-components';

import TextAnimationBlur from './TextAnimationBlur';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';

import * as Grid from '../styles/grid';

import { ReactComponent as Share } from '../assets/icons/share.svg';

const Container = styled(Grid.Container)`
  background: ${COLORS.black.css};
  color: ${COLORS.white.css};
  margin-top: ${SPACING(12)}px;
  padding-top: ${SPACING(14)}px;
  padding-bottom: ${SPACING(20)}px;
`;

const Heading = styled(Grid.Cell)`
  ${TYPO.regular2}
  color: ${COLORS.dim.css};

  h2 {
    ${TYPO.h2};
    color: ${COLORS.white.css};
    margin-bottom: ${SPACING(1)}px;
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: ${SPACING(2)}px;
`;

const List = styled(Grid.Cell)`
  ${TYPO.quote}
  margin-top: ${SPACING(2) + 30}px;
`;

type ItemProps = {
  last?: boolean;
};

const Item = styled.a<ItemProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.last ? 0 : SPACING(4))}px;

  span:nth-child(1) {
    ${TYPO.writingPost}
    margin-bottom: ${SPACING(1)}px;
  }
  span:nth-child(2) {
    display: flex;
    align-items: center;
    ${TYPO.small}
    color: ${COLORS.dim.css};
    transition: color 0.2s ease;

    svg {
      margin-right: ${SPACING(0.5)}px;
    }
  }

  &:hover span:nth-child(2) {
    color: ${COLORS.white.css};
  }
`;

const Splash = () => {
  return (
    <Container id="writing">
      <Heading
        start={2}
        columns={3}
        responsive={{ small: { start: 2, columns: 10 } }}
      >
        <Sticky>
          <h2>
            <TextAnimationBlur>Writing</TextAnimationBlur>
          </h2>
          I sometimes like to write about design, creativity and technology
        </Sticky>
      </Heading>
      <List
        start={6}
        columns={6}
        responsive={{ small: { start: 2, columns: 10 } }}
      >
        <Item
          href="https://github.com/dpwoert/magic-circle#readme"
          target="_blank"
        >
          <span>Magic Circle: My editor for creative collaboration</span>
          <span>
            <Share /> 5 min read
          </span>
        </Item>
        <Item href="http://thesis.dpwoert.com" target="_blank">
          <span>Refactoring collaboration</span>
          <span>
            <Share /> 90 min read
          </span>
        </Item>
        <Item
          href="https://uxdesign.cc/why-play-can-improve-the-interdisciplinary-collaboration-in-your-team-8d7fd1ce32f8"
          target="_blank"
        >
          <span>
            Why play can improve the interdisciplinary collaboration in your
            team
          </span>
          <span>
            <Share /> 13 min read on Medium
          </span>
        </Item>
        <Item
          href="https://uxdesign.cc/pragmatic-design-how-does-design-thinking-fit-into-the-real-word-3ec55c9d97cb"
          target="_blank"
          last
        >
          <span>
            Pragmatic design: how does design thinking fit into the real world?
          </span>
          <span>
            <Share /> 9 min read on Mediunm
          </span>
        </Item>
      </List>
    </Container>
  );
};

export default Splash;
