import React from 'react';
import styled from 'styled-components';

import TextAnimationTransform from './TextAnimationTransform';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import SPACING from '../styles/spacing';
import * as Grid from '../styles/grid';

const Container = styled(Grid.Container)`
  margin-top: ${SPACING(24)}px;
  color: ${COLORS.white.css};
  background: ${COLORS.black.css};
`;

const Quote = styled(Grid.Cell)`
  ${TYPO.quote};

  div {
    ${TYPO.regular}
    color: ${COLORS.dim.css};
    margin-bottom: ${SPACING(0.5)}px;
  }
`;

type SectionQuoteProps = {
  heading?: string;
  quote: string;
};

const SectionQuote = ({ heading, quote }: SectionQuoteProps) => {
  return (
    <Container>
      <Quote start={2} columns={10}>
        {heading && <div>{heading}</div>}
        <TextAnimationTransform>{quote}</TextAnimationTransform>
      </Quote>
    </Container>
  );
};

export default SectionQuote;
